import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { MoreMenuItem } from 'components/common/Menu';
import { typography } from 'components/common/Typography/styles';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { IconLinearMessageText } from 'components/icons/components/linear/IconLinearMessageText';
import { IconLinearTagRight } from 'components/icons/components/linear/IconLinearTagRight';
import { IconLinearTagUser } from 'components/icons/components/linear/IconLinearTagUser';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import {
  SocialMediaListeningFilterByDateRangeButton,
  useSLBrandPermissions,
} from 'features/socialMediaListening';
import { TopicLabel } from 'features/topic';
import {
  CommentEnrichmentType,
  CommentInsightsCategoryType,
  CommentTargetType,
  Platform,
  PlotFeature,
  useGetBrandsForCommentInsightsFiltersQuery,
  useGetCategoriesForCommentInsightsFiltersQuery,
  useGetOrganizationSocialPostCommentLabelsForCommentInsightsFiltersQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { CompetitorLabel } from '../../editCompetitorLabels/CompetitorLabel';

type Props = {
  commentInsightsCategoryType: CommentInsightsCategoryType;
  currentBrandId: string;
  trackingBrandId: string;
  platforms: Platform[];
  hideTopics?: boolean;
  currentDateRange?: [Date, Date];
  onUpdateFilters: (filters: {
    labels: string[];
    categories: string[];
    products: string[];
    dateRange: [Date, Date];
    topicIds?: string[];
    commentEnrichmentTypes?: CommentEnrichmentType[];
    commentTargetTypes?: CommentTargetType[];
  }) => void;
};

type CommentEnrichmentTypeOption = CommentEnrichmentType | 'OTHERS';

export const CompetitorCommentInsightsFilters = ({
  commentInsightsCategoryType,
  currentBrandId,
  trackingBrandId,
  platforms,
  currentDateRange,
  hideTopics,
  onUpdateFilters,
}: Props) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const { hasFullAccess } = useSLBrandPermissions({ brandId: currentBrandId });

  const { data: brandsData } = useGetBrandsForCommentInsightsFiltersQuery({
    skip: hideTopics,
  });

  const { data: labelsData } =
    useGetOrganizationSocialPostCommentLabelsForCommentInsightsFiltersQuery({
      variables: {
        data: {
          filters: {},
          organizationId: user?.organization.id!,
        },
      },
      skip: !user?.organization.id,
    });

  const { data: categoriesData } =
    useGetCategoriesForCommentInsightsFiltersQuery({
      variables: {
        data: {
          brandId: trackingBrandId,
          platforms,
          type: commentInsightsCategoryType,
          startDate: currentDateRange?.[0],
          endDate: currentDateRange?.[1],
        },
      },
      skip: !trackingBrandId,
    });

  const allLabels = labelsData?.organizationSocialPostCommentLabels.data || [];

  const topics =
    brandsData?.brands.find((brand) => brand.id === trackingBrandId)?.topics ||
    [];

  const allCategories =
    categoriesData?.socialPostCommentCategoriesForBrand || [];
  const allProducts = ['Product 1', 'Product 2', 'Product 3', 'Product 4'];

  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);

  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(allCategories);

  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(allProducts);

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedEnrichmentTypes, setSelectedEnrichmentTypes] = useState<
    CommentEnrichmentTypeOption[]
  >([]);
  const [selectedTargetTypes, setSelectedTargetTypes] = useState<
    CommentTargetType[]
  >([]);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);

  useEffect(() => {
    setFilteredCategories(
      allCategories.filter((category) =>
        category.toLowerCase().includes(categorySearchTerm.toLowerCase()),
      ),
    );
  }, [categorySearchTerm]); //eslint-disable-line

  useEffect(() => {
    setFilteredCategories(allCategories);
  }, [categoriesData]); // eslint-disable-line

  useEffect(() => {
    setFilteredProducts(
      allProducts.filter((product) =>
        product.toLowerCase().includes(productSearchTerm.toLowerCase()),
      ),
    );
  }, [productSearchTerm]); //eslint-disable-line

  useEffect(() => {
    onUpdateFilters({
      labels: selectedLabels,
      categories: selectedCategories,
      products: selectedProducts,
      topicIds: selectedTopicIds,
      dateRange,
      commentEnrichmentTypes: selectedEnrichmentTypes.length
        ? [
            ...(selectedEnrichmentTypes.includes(CommentEnrichmentType.Question)
              ? [CommentEnrichmentType.Question]
              : []),
            ...(selectedEnrichmentTypes.includes(
              CommentEnrichmentType.Sentiment,
            )
              ? [CommentEnrichmentType.Sentiment]
              : []),
            ...(selectedEnrichmentTypes.includes('OTHERS')
              ? [CommentEnrichmentType.None, CommentEnrichmentType.Request]
              : []),
          ]
        : undefined,
      commentTargetTypes: selectedTargetTypes.length
        ? selectedTargetTypes
        : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLabels,
    selectedCategories,
    selectedProducts,
    dateRange,
    selectedTopicIds,
    selectedEnrichmentTypes,
    selectedTargetTypes,
  ]);

  const getSelectedFiltersCount = (total: number) => {
    if (!total) return null;
    return (
      <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
        ({total})
      </Typography>
    );
  };

  return (
    <Box display="flex" gap={3} alignItems="center">
      {!hideTopics && (
        <ContextMenu
          sx={{
            '& .MuiPaper-root': {
              maxHeight: 400,
              overflowY: 'auto',
            },
            '& .MuiMenuItem-root': {
              p: 0,
              color: theme.colors?.primary.black,
            },
          }}
          options={[
            ...topics.map((topic) => ({
              renderOption: () => (
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  py={2}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTopicIds((prev) =>
                      prev.includes(topic.id)
                        ? prev.filter((item) => item !== topic.id)
                        : [...prev, topic.id],
                    );
                  }}
                >
                  <Checkbox
                    sx={{
                      '&.Mui-checked': { color: theme.colors?.utility[800] },
                    }}
                    checked={selectedTopicIds.includes(topic.id)}
                  />
                  <Box flex={1} mr={4}>
                    <Typography
                      fontWeight={500}
                      variant="body-lg"
                      display="block"
                    >
                      {topic.name}
                    </Typography>
                    <TopicLabel topic={topic} />
                  </Box>
                  <IconButton
                    sx={{ p: 0, ml: 2 }}
                    disableRipple
                    onClick={() =>
                      navigate(
                        PlotRoutes.socialListeningSettings({
                          tab: 'topics',
                          topicId: topic.id,
                          brandId: currentBrandId,
                        }),
                      )
                    }
                  >
                    {hasFullAccess && <IconOutlineEdit2 size={20} />}
                  </IconButton>
                </Box>
              ),
            })),
            ...(hasFullAccess
              ? [
                  {
                    renderCustomComponent: () => (
                      <Box
                        sx={{
                          borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                        }}
                      />
                    ),
                  },
                  {
                    renderCustomComponent: () => (
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <MoreMenuItem
                          disableRipple
                          sx={{
                            minWidth: 240,
                            width: '100%',
                          }}
                          label={
                            <Typography
                              display="inline-block"
                              variant="body-md"
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                borderRadius: theme.spacing(2),
                                p: theme.spacing(3, 2),
                                '&:hover': {
                                  backgroundColor: theme.colors?.utility[275],
                                },
                              }}
                            >
                              Create a New Topic
                              <IconLinearArrowRight size={16} />
                            </Typography>
                          }
                        >
                          <Box
                            sx={{
                              width: 270,
                              py: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                            }}
                          >
                            <Link
                              to={PlotRoutes.socialListeningOnboarding(
                                'topic',
                                currentBrandId,
                              )}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 2,
                                  padding: theme.spacing(3),
                                  borderRadius: theme.spacing(3),
                                  '&:hover': {
                                    backgroundColor: theme.colors?.utility[275],
                                  },
                                }}
                              >
                                <Typography variant="body-lg" fontWeight={500}>
                                  Topic Insights
                                </Typography>
                                <Typography
                                  variant="body-sm"
                                  fontWeight={500}
                                  color={theme.colors?.utility[900]}
                                >
                                  Track conversations across customized topics
                                </Typography>
                              </Box>
                            </Link>
                            {creatorTrackingEnabled && (
                              <Link
                                to={PlotRoutes.socialListeningOnboarding(
                                  'creator',
                                  currentBrandId,
                                )}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    padding: theme.spacing(3),
                                    borderRadius: theme.spacing(3),
                                    '&:hover': {
                                      backgroundColor:
                                        theme.colors?.utility[275],
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="body-lg"
                                    fontWeight={500}
                                  >
                                    Creator Focus
                                  </Typography>
                                  <Typography
                                    variant="body-sm"
                                    fontWeight={500}
                                    color={theme.colors?.utility[900]}
                                  >
                                    Track specific creators discussing your key
                                    topics
                                  </Typography>
                                </Box>
                              </Link>
                            )}
                          </Box>
                        </MoreMenuItem>
                      </Box>
                    ),
                  },
                ]
              : []),
          ]}
          renderButton={() => (
            <Button
              sx={{
                backgroundColor: theme.colors?.utility[275],
                color: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                display: 'flex',
                gap: 2,
                p: theme.spacing(1, 2),
                alignItems: 'center',
                '&:hover': { backgroundColor: theme.colors?.utility[275] },
              }}
            >
              <IconLinearClipboardText size={16} />

              <Typography fontWeight={500} variant="body-xl">
                Topics
              </Typography>
              {getSelectedFiltersCount(selectedTopicIds.length)}
              <IconLinearArrowDown size={16} />
            </Button>
          )}
        />
      )}
      <ContextMenu
        sx={{
          '& .MuiPaper-root': {
            maxHeight: 400,
            overflowY: 'auto',
          },
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          ...allLabels.map((label) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedLabels((prev) =>
                    prev.includes(label.id)
                      ? prev.filter((item) => item !== label.id)
                      : [...prev, label.id],
                  );
                }}
              >
                <Checkbox
                  sx={{
                    '&.Mui-checked': { color: theme.colors?.utility[800] },
                  }}
                  checked={selectedLabels.includes(label.id)}
                />
                <CompetitorLabel
                  label={label.label}
                  componentProps={{
                    sx: {
                      fontSize: theme.spacing(3.5),
                    },
                  }}
                />
              </Box>
            ),
          })),
        ]}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <IconLinearTagRight size={16} />

            <Typography fontWeight={500} variant="body-xl">
              Labels
            </Typography>
            {getSelectedFiltersCount(selectedLabels.length)}
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      />

      <ContextMenu
        sx={{
          '& .MuiPaper-root': {
            maxHeight: 400,
            overflowY: 'auto',
          },
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          {
            renderCustomComponent: () => (
              <Box px={2}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={categorySearchTerm}
                  onChange={(e) => setCategorySearchTerm(e.target.value)}
                  sx={{
                    my: theme.spacing(1),
                    width: '100%',
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.utility[250],
                    '& .MuiOutlinedInput-root': {
                      borderRadius: `${theme.spacing(5)} !important`,
                      border: 'none',
                    },
                    '& .MuiInputBase-input': {
                      ...typography['headline-sm'],
                      borderRadius: `${theme.spacing(5)} !important`,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </Box>
            ),
          },
          ...(!filteredCategories.length
            ? [
                {
                  renderCustomComponent: () => (
                    <Box>
                      <Typography
                        variant="body-lg"
                        fontWeight={500}
                        sx={{ p: theme.spacing(2) }}
                        color={theme.colors?.utility[700]}
                      >
                        No Categories
                      </Typography>
                    </Box>
                  ),
                },
              ]
            : []),
          ...filteredCategories.map((category) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCategories((prev) =>
                    prev.includes(category)
                      ? prev.filter((item) => item !== category)
                      : [...prev, category],
                  );
                }}
              >
                <Checkbox
                  sx={{
                    '&.Mui-checked': { color: theme.colors?.utility[800] },
                  }}
                  checked={selectedCategories.includes(category)}
                />
                <Typography variant="body-lg">{category}</Typography>
              </Box>
            ),
          })),
        ]}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <IconCustomSparkles size={16} />

            <Typography fontWeight={500} variant="body-xl">
              Category
            </Typography>
            {getSelectedFiltersCount(selectedCategories.length)}
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      />

      {/* Not showing products for now */}
      {/* <ContextMenu
        sx={{
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          {
            renderCustomComponent: () => (
              <Box px={2}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={productSearchTerm}
                  onChange={(e) => setProductSearchTerm(e.target.value)}
                  sx={{
                    my: theme.spacing(1),
                    width: '100%',
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.utility[250],
                    '& .MuiOutlinedInput-root': {
                      borderRadius: `${theme.spacing(5)} !important`,
                      border: 'none',
                    },
                    '& .MuiInputBase-input': {
                      ...typography['headline-sm'],
                      borderRadius: `${theme.spacing(5)} !important`,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </Box>
            ),
          },
          ...filteredProducts.map((product) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProducts((prev) =>
                    prev.includes(product)
                      ? prev.filter((item) => item !== product)
                      : [...prev, product],
                  );
                }}
              >
                <Checkbox
                  sx={{
                    '&.Mui-checked': { color: theme.colors?.utility[800] },
                  }}
                  checked={selectedProducts.includes(product)}
                />
                <Typography variant="body-lg">{product}</Typography>
              </Box>
            ),
          })),
        ]}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(35, 6, 3, 0.05);',
                p: theme.spacing(1),
                borderRadius: theme.spacing(1),
              }}
            >
              <IconLinearTag2 size={16} />
            </Box>
            <Typography fontWeight={500} variant="body-xl">
              Product
            </Typography>
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      /> */}

      <SocialMediaListeningFilterByDateRangeButton
        onChange={(range) => {
          if (range[0] && range[1]) {
            setDateRange(range as [Date, Date]);
          }
        }}
        selectedDateRange={dateRange}
      />

      <ContextMenu
        sx={{
          '& .MuiPaper-root': {
            maxHeight: 400,
            overflowY: 'auto',
          },
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          { value: CommentEnrichmentType.Question, label: 'Question' },
          { value: CommentEnrichmentType.Sentiment, label: 'Opinion' },
          { value: 'OTHERS', label: 'Others' },
        ].map((commentEnrichmentType) => ({
          renderOption: () => (
            <Box
              display="flex"
              alignItems="center"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedEnrichmentTypes((prev) =>
                  prev.includes(
                    commentEnrichmentType.value as CommentEnrichmentTypeOption,
                  )
                    ? prev.filter(
                        (item) =>
                          item !==
                          (commentEnrichmentType.value as CommentEnrichmentTypeOption),
                      )
                    : [
                        ...prev,
                        commentEnrichmentType.value as CommentEnrichmentTypeOption,
                      ],
                );
              }}
            >
              <Checkbox
                sx={{
                  '&.Mui-checked': { color: theme.colors?.utility[800] },
                }}
                checked={selectedEnrichmentTypes.includes(
                  commentEnrichmentType.value as CommentEnrichmentTypeOption,
                )}
              />
              <Typography variant="body-lg">
                {commentEnrichmentType.label}
              </Typography>
            </Box>
          ),
        }))}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <IconLinearMessageText size={16} />

            <Typography variant="subhead-xl">Intent</Typography>
            {getSelectedFiltersCount(selectedEnrichmentTypes.length)}
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      />

      <ContextMenu
        sx={{
          '& .MuiPaper-root': {
            maxHeight: 400,
            overflowY: 'auto',
          },
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          { value: CommentTargetType.Product, label: 'Product' },
          { value: CommentTargetType.Brand, label: 'Brand' },
          { value: CommentTargetType.VideoTopic, label: 'Topic of the post' },
          { value: CommentTargetType.Creator, label: 'Creator of the post' },
        ].map((targetType) => ({
          renderOption: () => (
            <Box
              display="flex"
              alignItems="center"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTargetTypes((prev) =>
                  prev.includes(targetType.value)
                    ? prev.filter((item) => item !== targetType.value)
                    : [...prev, targetType.value],
                );
              }}
            >
              <Checkbox
                sx={{
                  '&.Mui-checked': { color: theme.colors?.utility[800] },
                }}
                checked={selectedTargetTypes.includes(targetType.value)}
              />
              <Typography variant="body-lg">{targetType.label}</Typography>
            </Box>
          ),
        }))}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <IconLinearTagUser size={16} />

            <Typography fontWeight={500} variant="body-xl">
              Target
            </Typography>
            {getSelectedFiltersCount(selectedTargetTypes.length)}
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      />
    </Box>
  );
};
