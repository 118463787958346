import { gql } from '@apollo/client';
import { OrganizationBillingFragmentFileStorageLimitProgressFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { Box, SxProps, Typography } from '@mui/material';
import { ProgressBar } from 'components/common/ProgressBar';
import { useMemo } from 'react';
import { Parser } from 'utils/parser';
import { IconCustomInfinity } from 'components/icons/components/custom/IconCustomInfinity';

gql`
  fragment OrganizationBillingFragmentFileStorageLimitProgress on OrganizationBillingModel {
    id
    fileStorageLimitInMb
    fileStorageUsedInMb
  }
`;

type BillingFileStorageLimitProgressProps = {
  organizationBilling: OrganizationBillingFragmentFileStorageLimitProgressFragment;
  filledColor?: string;
  sx?: SxProps;
};

export const BillingFileStorageLimitProgress = (
  props: BillingFileStorageLimitProgressProps,
) => {
  const { organizationBilling, filledColor, sx } = props;

  const percentageUsed = useMemo(() => {
    if (organizationBilling.fileStorageLimitInMb) {
      return Parser.to2Decimal(
        (organizationBilling.fileStorageUsedInMb /
          organizationBilling.fileStorageLimitInMb) *
          100,
      );
    }
    return 0;
  }, [organizationBilling]);

  const progressBarFilledColor = useMemo(() => {
    if (percentageUsed >= 0 && percentageUsed < 75) {
      return theme.colors?.utility['green-2'];
    }

    if (percentageUsed >= 75 && percentageUsed < 95) {
      return theme.colors?.utility['yellow-3'];
    }

    if (percentageUsed >= 95) {
      return theme.colors?.utility['pink-3'];
    }

    return theme.colors?.primary.maroon;
  }, [percentageUsed]);

  return !organizationBilling.fileStorageLimitInMb ? null : (
    <Box display="flex" flexDirection="column" gap={theme.spacing(2)} sx={sx}>
      <Box>
        <Typography color={theme.colors?.utility[1000]} variant="headline-xxs">
          Storage
        </Typography>

        {organizationBilling.fileStorageLimitInMb && (
          <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
            {' '}
            • {percentageUsed}% used
          </Typography>
        )}
      </Box>

      {organizationBilling.fileStorageLimitInMb ? (
        <>
          <ProgressBar
            completed={organizationBilling.fileStorageUsedInMb}
            total={organizationBilling.fileStorageLimitInMb}
            bgColor={theme.colors?.utility[275]}
            filledColor={filledColor ?? progressBarFilledColor}
          />

          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            {Parser.toGB(organizationBilling.fileStorageUsedInMb)}GB of{' '}
            {Parser.toGB(organizationBilling.fileStorageLimitInMb)}GB
          </Typography>
        </>
      ) : (
        <Box
          display="flex"
          gap={theme.spacing(1)}
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconCustomInfinity color={theme.colors?.utility[900]} />
          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            Unlimited Storage
          </Typography>
        </Box>
      )}
    </Box>
  );
};
