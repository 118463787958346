import { Box, BoxProps, IconButton, SxProps } from '@mui/material';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { useCallback, useMemo } from 'react';
import { theme } from 'styles/theme';
import { EditableChip, EditableChipProps } from '../Chip';

export type MultipleTagInputProps = {
  value: string[];
  sx?: SxProps;
  componentsProps?: {
    chip?: Pick<EditableChipProps, 'sx' | 'variant' | 'componentsProps'>;
    input?: Pick<BoxProps<'input'>, 'sx' | 'placeholder' | 'autoFocus'>;
  };
  addMode?: 'input' | 'button';
  onChange: (value: string[]) => void;
};

/**
 * This component is an input that allows users to add multiple tags, where each tag is editable.
 * I tried to use Autocomplete from MUI, but it was not flexible enough for the use cases we are aiming for.
 */
export const MultipleTagInput = (props: MultipleTagInputProps) => {
  const {
    value,
    sx,
    componentsProps = {},
    addMode = 'input',
    onChange,
  } = props;

  const onAddTag = useCallback(
    (tag: string) => {
      onChange([...value, tag]);
    },
    [value, onChange],
  );

  const addTriggerRender = useMemo(() => {
    switch (addMode) {
      case 'input': {
        return (
          <Box
            component="input"
            type="text"
            {...componentsProps.input}
            sx={{
              ...componentsProps.input?.sx,
              flex: 1,
              bgcolor: 'transparent',
              outline: 'none',
              border: 'none',
              minWidth: '0',
            }}
            onKeyDown={(e) => {
              e.stopPropagation();

              if (e.key === 'Enter' && e.currentTarget.value) {
                onAddTag(e.currentTarget.value);
                e.currentTarget.value = '';
              }
            }}
          />
        );
      }
      case 'button':
        return (
          <IconButton
            size="small"
            sx={{
              p: 0,
            }}
            onClick={(e) => {
              e.stopPropagation();
              onAddTag('');
            }}
          >
            <IconBoldAddCircle
              size={20}
              style={{
                color: theme.colors?.primary.black,
              }}
            />
          </IconButton>
        );
      default: {
        return null;
      }
    }
  }, [addMode, componentsProps, onAddTag]);

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {value.map((tag, index) => (
        <EditableChip
          key={index}
          {...componentsProps.chip}
          componentsProps={{
            ...componentsProps.chip?.componentsProps,
            input: {
              ...componentsProps.chip?.componentsProps?.input,
              // Reusing the input styles because we'd want the same styles for the input in the chip
              sx: {
                ...componentsProps.input?.sx,
              },
            },
          }}
          label={tag}
          onDelete={() => {
            const newValue = [...value];
            newValue.splice(index, 1);
            onChange(newValue);
          }}
          onSave={(newValue) => {
            const newTags = [...value];
            newTags[index] = newValue;
            onChange(newTags);
          }}
          isInEditModeByDefault={!tag} // If the tag is empty, we should start in edit mode
        />
      ))}
      {addTriggerRender}
    </Box>
  );
};
