import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, Grid, IconButton } from '@mui/material';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CommandHandler } from 'contexts/commands/types';
import { useUserContext } from 'contexts/users/User.context';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import { PermissionSummaryView } from 'features/permission';
import { PostIcon, PostPreview, PostTitle } from 'features/post';
import { usePostPermissionSuggestedAuthDialog } from 'features/post-permission';
import { getMentionedUserIds } from 'features/tiptap';
import {
  GeneralPermission,
  PermissionLevel,
  PlotFeature,
  PostFragmentPostCommandFragment,
  PostFragmentPostCommandFragmentDoc,
  UpdatePostPermissionsInputDataV2,
  useDeletePostForPostAfterCreationHandlerMutation,
  useProcessPostsAfterCreationForPostAfterCreationHandlerMutation,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { AddPostsForOutboundCommentTrackingButton } from './AddPostsForOutboundCommentTrackingButton';
import { CommentButton } from './CommentButton';
import { OrganizeButton } from './OrganizeButton';
import { StyledIconButton, tooltipStyle } from './styles';

// eslint-disable-next-line
gql`
  mutation ProcessPostsAfterCreationForPostAfterCreationHandler(
    $data: ProcessPostsAfterCreationInput!
  ) {
    processPostsAfterCreation(data: $data) {
      id
      ...PostFragmentPostCommand
    }
  }
  ${PostFragmentPostCommandFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeletePostForPostAfterCreationHandler($id: String!) {
    deletePost(id: $id) {
      message
      success
    }
  }
`;

export type PostAfterCreationHandlerInitialValues = {
  posts?: PostFragmentPostCommandFragment[];
};

export type PostAfterCreationHandlerContext = {
  initialValues?: PostAfterCreationHandlerInitialValues;
  postCreationStartAt?: number;
};

export const PostAfterCreationHandler: CommandHandler<
  COMMAND_TYPE.POST_AFTER_CREATION
> = (props) => {
  const {
    commandId,
    context: { initialValues = {}, postCreationStartAt } = {},
  } = props;

  const [posts, setPosts] = useState(initialValues?.posts || []);
  const postIds = posts.map((p) => p.id) || [];

  const { user, orgBilling } = useUserContext();
  const socialListeningEnabled = Boolean(orgBilling?.socialListeningEnabled);

  const { updateActiveCommand } = useCommandContext();
  const { collectionId } = useCollectionIdFromParams();

  const {
    isOpen: isAfterCreationDialogOpen,
    onOpen: openAfterCreationDialog,
    onClose: closeAfterCreationDialog,
  } = useDisclosure();

  const { dialog: deletePostDialog, onOpen: openDeletePostConfirmationDialog } =
    useConfirmationDialog();

  const { isFeatureEnabled } = useFeatureFlagContext();

  // States for collection popover
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    collectionId ? [collectionId] : [],
  );
  const [willAddPostsToFavorites, setWillAddPostsToFavorites] = useState(false);
  const [willSavePostsToContentCalendar, setWillSavePostsToContentCalendar] =
    useState(false);

  // State for post suggested auth dialog
  const {
    postSuggestedAuth,
    setPostSuggestedAuth,
    renderUI: renderPostPermissionSuggestedAuthDialog,
    showInvitationUI,
    note,
    shouldCreateComment,
  } = usePostPermissionSuggestedAuthDialog({
    postIds,
    collectionIds: selectedCollectionIds || [],
    componentsProps: {
      dialogBody: {
        shouldRenderCopyLink: false,
        componentsProps: {
          header: {
            sx: { height: 62 },
          },
          body: {
            sx: { height: 260 },
          },
          permissionSelectView: {
            componentProps: {
              noteContainerSx: {
                height: 180,
              },
            },
          },
        },
      },
      dialog: {
        customBtn: <>&nbsp;</>, // render nothing
        componentsProps: {
          dialog: {
            PaperProps: {
              sx: {
                height: 420,
                width: 404,
              },
            },
          },
        },
      },
    },
  });

  // State for comment popover
  const [comment, setComment] = useState('');

  const [
    processPostsAfterCreation,
    { loading: isProcessingPostsAfterCreation },
  ] = useProcessPostsAfterCreationForPostAfterCreationHandlerMutation();
  const [deletePost] = useDeletePostForPostAfterCreationHandlerMutation();

  // State for outbound comment tracking
  // Will only show the button if the posts contains TikTok or Instagram posts
  const canShowButtonForOutboundCommentTracking =
    socialListeningEnabled &&
    isFeatureEnabled(PlotFeature.ManualOutboundUpload) &&
    posts.some((p) => {
      const url = p.urlMetadata?.[0].url ?? '';
      return url.includes('tiktok.com') || url.includes('instagram.com');
    });
  const [
    brandIdsToAddPostsForOutboundCommentTracking,
    setBrandIdsToAddPostsForOutboundCommentTracking,
  ] = useState<string[]>([]);

  const onSubmit = async () => {
    try {
      const postPermissions: UpdatePostPermissionsInputDataV2 = {
        inviteMembers: postSuggestedAuth.suggestedInviteMembers
          ? {
              memberInvites: [],
              shouldCreateComment,
              message: note,
              emailInvites: postSuggestedAuth.suggestedInviteMembers.flatMap(
                (member) =>
                  postIds.map((postId) => ({
                    email: member.user.email,
                    permission: member.permissionLevel,
                    postId,
                  })),
              ),
            }
          : undefined,
        removeMembers: undefined,
        generalPermission: postSuggestedAuth.generalPermission
          ? {
              postIds,
              generalPermission: postSuggestedAuth.generalPermission,
              permissionLevel: postSuggestedAuth.permissionLevel,
            }
          : undefined,
        sendNotification: true,
      };

      await processPostsAfterCreation({
        variables: {
          data: {
            postIds,
            postPermissions,
            addPostsToFavorites: willAddPostsToFavorites,
            collectionIds: selectedCollectionIds,
            comment,
            savePostsToContentCalendar: willSavePostsToContentCalendar,
            brandIdsToAddPostsForOutboundCommentTracking,
          },
        },
      });

      updateActiveCommand(commandId, {
        status: 'completed',
        data: (initialValues?.posts || []).map((p) => ({
          ...p,
          collections: selectedCollectionIds.map((cId) => ({
            id: cId,
            __typename: 'CollectionModel',
          })),
        })),
      });

      closeAfterCreationDialog();
    } catch (error) {
      console.log(error);
    }
  };

  const onDeletePost = (id: string) => {
    deletePost({
      variables: {
        id,
      },
      update: (cache) => {
        evictObject(cache, id, 'PostModel');
        setPosts([...posts.filter((p) => p.id !== id)]);
      },
    });
  };

  const onClose = async () => {
    onSubmit();
  };

  const analytics = useAnalytics();

  useEffect(() => {
    // === Analytics ===
    // Track the time it takes for post-creation commands to finish
    if (postCreationStartAt) {
      analytics.track(EventName.FEPostCreation, {
        time: performance.now() - postCreationStartAt, // in milliseconds
      });
    }
    // === End of Analytics ===

    openAfterCreationDialog();
    updateActiveCommand(commandId, {
      status: 'processing',
    });
  }, []); // eslint-disable-line -- triger flow on mount

  const onCloseCommentPopover = () => {
    // Get mentioned user ids from the comment html
    const mentionIds = getMentionedUserIds(comment).filter(
      (id) => id !== user?.id,
    );

    if (mentionIds.length === 0) {
      return;
    }

    const users = [
      ...(user?.organization.externalUsers || []),
      ...(user?.organization.users || []),
    ];

    const invitedMembers: any = [];

    for (const mentionId of mentionIds) {
      const member = users.find((u) => u.id === mentionId);

      const isUserExistInInvite = postSuggestedAuth.suggestedInviteMembers.find(
        (m) => m.user.id === mentionId,
      );
      // If the mentioned user is not in the invite list, add it to the `invitedMembers`
      if (member && !isUserExistInInvite) {
        // If general permission is set to "Org" level, check the user organization id against the mentioned user's organization id
        // If the mentioned user is not in the same organization as the user, add it to the `invitedMemberIds`; if they are in the same organization, it means the user already has access to the post
        if (
          postSuggestedAuth?.generalPermission ===
          GeneralPermission.OrganizationMembers
        ) {
          if (member.organization.id !== user?.organization.id) {
            invitedMembers.push(member);
          }
        } else {
          // This case only happens when the post is private
          invitedMembers.push(member);
        }
      }
    }

    setPostSuggestedAuth((draft) => {
      invitedMembers.map((u) => {
        draft.suggestedInviteMembers.push({
          user: u,
          permissionLevel:
            user?.personalSettings
              ?.postGeneralPermissionDefaultPermissionLevel ??
            PermissionLevel.View,
        });
      });
    });
  };

  if (!isAfterCreationDialogOpen) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'relative',
          width: '100%',
          maxWidth: 470,
          transition: 'all 0.2s',
          bgcolor: '#E6DDED',
          borderRadius: 8,
          p: 3,
        },
      }}
    >
      <Box
        sx={{
          aspectRatio: '1 / 1',
          p: 4,
          bgcolor: '#23060312',
          borderRadius: 8,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{ mx: 'auto', mb: 4 }}
          component="button"
          type="button"
          onClick={showInvitationUI}
        >
          <PermissionSummaryView
            organizationName={user?.organization.name || ''}
            generalPermission={postSuggestedAuth.generalPermission}
            invitedMembers={postSuggestedAuth.suggestedInviteMembers}
          />
          {/* These following styles are to ensure the default Share button doesn't show in the UI */}
          <Box
            sx={{
              width: 0,
              position: 'absolute',
            }}
          >
            {renderPostPermissionSuggestedAuthDialog()}
          </Box>
        </Box>
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          <Grid container spacing={4}>
            {posts?.map((post) => (
              <Grid
                item
                key={post.id}
                {...(posts.length === 1
                  ? { xs: 12 }
                  : posts.length === 2
                  ? { xs: 6 }
                  : { xs: 4 })}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <PostPreview
                      post={post}
                      sx={{
                        border: `6px solid ${theme.colors?.primary.parchment}`,
                        borderRadius: 3,
                        aspectRatio: '3 / 4',
                        maxWidth: posts.length === 1 ? 200 : '100%',
                      }}
                    />

                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 2,
                        padding: 1.5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          width: '100%',
                          padding: theme.spacing(2, 3),
                          backgroundImage:
                            'linear-gradient(0deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.4) 100%)',
                          borderRadius: 'inherit',
                        }}
                      >
                        <PostTitle
                          post={post}
                          variant="popover"
                          sx={{
                            color: theme.colors?.primary.parchment,
                          }}
                        />
                        {posts.length > 1 && (
                          <PostIcon
                            post={post}
                            size={16}
                            style={{
                              color: theme.colors?.primary.parchment,
                            }}
                          />
                        )}
                      </Box>
                      {posts.length > 1 && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            backgroundImage:
                              'linear-gradient(180deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.4) 100%)',
                            borderRadius: 'inherit',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              openDeletePostConfirmationDialog({
                                subtitle: `Once it removed, this post will no longer be accessible.`,
                                onConfirm: () => {
                                  onDeletePost(post.id);
                                },
                              });
                            }}
                          >
                            <IconBoldTrash
                              size={16}
                              color={theme.colors?.primary.parchment}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          px: 4,
          pt: 8,
          pb: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OrganizeButton
            postIds={postIds}
            collectionIds={selectedCollectionIds}
            setCollectionIds={setSelectedCollectionIds}
            willAddPostsToFavorites={willAddPostsToFavorites}
            setWillAddPostsToFavorites={setWillAddPostsToFavorites}
          />
          <CommentButton
            comment={comment}
            setComment={setComment}
            onCloseCommentPopover={onCloseCommentPopover}
          />
          {isFeatureEnabled(PlotFeature.ContentCalendar) && (
            <StyledIconButton
              tooltip="Add to Content Calendar"
              onClick={() => {
                setWillSavePostsToContentCalendar(
                  !willSavePostsToContentCalendar,
                );
              }}
              tooltipPopperProps={{
                sx: tooltipStyle,
              }}
              placement="top"
              sx={{
                ...(willSavePostsToContentCalendar
                  ? {
                      bgcolor: `${theme.colors?.utility['yellow-3']} !important`,
                    }
                  : {}),
              }}
            >
              <IconBoldCalendar />
            </StyledIconButton>
          )}
          {canShowButtonForOutboundCommentTracking && (
            <AddPostsForOutboundCommentTrackingButton
              brandIds={brandIdsToAddPostsForOutboundCommentTracking}
              onChange={setBrandIdsToAddPostsForOutboundCommentTracking}
            />
          )}
        </Box>
        <Button
          variant="pill"
          sx={{
            py: 2,
            px: 6,
          }}
          disabled={isProcessingPostsAfterCreation}
          onClick={onSubmit}
        >
          Done
        </Button>
      </Box>
      {deletePostDialog}
    </Dialog>
  );
};
