import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { CreatorHandleChip } from 'features/creator';
import {
  CompetitorFragmentBrandCompetitorInfoFragment,
  CreatorFragmentCreatorHandleChipFragmentDoc,
  Platform,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

type Props = {
  competitor: CompetitorFragmentBrandCompetitorInfoFragment;
  currentPlatform: Platform;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CompetitorFragmentBrandCompetitorInfo on CompetitorModel {
    id
    trackingBrand {
      id
      name
      creators {
        id
        profilePictureUrl
        ...CreatorFragmentCreatorHandleChip
      }
    }
  }
  ${CreatorFragmentCreatorHandleChipFragmentDoc}
`;

export const BrandCompetitorInfo = ({ competitor, currentPlatform }: Props) => {
  const platformCreator = useMemo(() => {
    return competitor.trackingBrand?.creators.find(
      (creator) => creator.platform === currentPlatform,
    );
  }, [competitor.trackingBrand?.creators, currentPlatform]);

  return (
    <Box display="flex" gap={3} alignItems="center">
      <Box
        component="img"
        src={platformCreator?.profilePictureUrl || ''}
        sx={{
          width: theme.spacing(18),
          height: theme.spacing(18),
          borderRadius: theme.spacing(100),
          border: `2px solid ${theme.colors?.utility[300]}`,
          backgroundColor: theme.colors?.utility[275],
        }}
      />
      <Box display="flex" gap={2} flexDirection="column">
        <Typography variant="headline-xl">
          {competitor.trackingBrand?.name}
        </Typography>
        {platformCreator && (
          <Box display="flex" gap={3} alignItems="center">
            <CreatorHandleChip creator={platformCreator} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
