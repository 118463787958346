/**
 * This should replace the existing useGraphDataMaker hook.
 */

import { ChartDataPoint } from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';

export type ChartDataPointWithDateRange = ChartDataPoint & {
  dateRange: [Date, Date];
};

export type UseGraphDataMakerNewProps = {
  dailyChartData: ChartDataPoint[];
  dateRange?: (Date | null)[];
};

export const useGraphDataMakerNew = (props: UseGraphDataMakerNewProps) => {
  const { dailyChartData, dateRange } = props;
  const [startDate, endDate] = dateRange || [];

  const stateDateUtc = moment(startDate).utc();
  const endDateUtc = moment(endDate).utc();

  const groupedDataByDay = useMemo(() => {
    return dailyChartData.reduce((acc, curr) => {
      const day = moment(curr.X).utc().dayOfYear();
      const year = moment(curr.X).utc().year();
      const dayStart = moment().utc().dayOfYear(day).year(year).startOf('day');

      const dayLabel = dayStart.format('MMM D');

      if (!acc[dayLabel]) {
        acc[dayLabel] = {
          value: 0,
          dateRange: [
            stateDateUtc.clone().startOf('day').toDate(),
            stateDateUtc.clone().endOf('day').toDate(),
          ],
        };
      }

      acc[dayLabel].value += curr.Y;

      return acc;
    }, {} as Record<string, { value: number; dateRange: [Date, Date] }>);
  }, [dailyChartData]);

  const groupedDataByWeek = useMemo(() => {
    return dailyChartData.reduce((acc, curr) => {
      const week = moment(curr.X).utc().isoWeek();
      const year = moment(curr.X).utc().isoWeekYear();
      const isoWeekStart = moment()
        .utc()
        .isoWeek(week)
        .isoWeekYear(year)
        .startOf('isoWeek');
      const isoWeekEnd = moment()
        .utc()
        .isoWeek(week)
        .isoWeekYear(year)
        .endOf('isoWeek');

      // Use startDate if startDate is > weekStart and < weekEnd
      const weekStart = stateDateUtc.isBetween(isoWeekStart, isoWeekEnd)
        ? stateDateUtc
        : isoWeekStart;
      // Use endDate if endDate is < weekEnd and > weekStart
      const weekEnd = endDateUtc.isBetween(isoWeekStart, isoWeekEnd)
        ? endDateUtc
        : isoWeekEnd;

      const weekLabel = `${weekStart.format('MMM D')} - ${weekEnd.format(
        'MMM D',
      )}`;

      if (!acc[weekLabel]) {
        acc[weekLabel] = {
          value: 0,
          dateRange: [weekStart.utc().toDate(), weekEnd.utc().toDate()],
        };
      }

      acc[weekLabel].value += curr.Y;

      return acc;
    }, {} as Record<string, { value: number; dateRange: [Date, Date] }>);
  }, [dailyChartData, startDate, endDate]);

  const groupedDataByMonth = useMemo(() => {
    return dailyChartData.reduce((acc, curr) => {
      const month = moment(curr.X).utc().month();
      const year = moment(curr.X).utc().year();
      const monthStart = moment()
        .utc()
        .month(month)
        .year(year)
        .startOf('month');

      const monthLabel = monthStart.format('MMM YYYY');

      if (!acc[monthLabel]) {
        acc[monthLabel] = {
          value: 0,
          dateRange: [
            moment(monthStart).utc().startOf('month').toDate(),
            moment(monthStart).utc().endOf('month').toDate(),
          ],
        };
      }

      acc[monthLabel].value += curr.Y;

      return acc;
    }, {} as Record<string, { value: number; dateRange: [Date, Date] }>);
  }, [dailyChartData]);

  // Depending on the total number of data points, we want to group them by day, week, or month
  // 1. If < 1 week, group by day
  // 2. If < 2 month, group by week
  // 3. Otherwise, group by month
  const preparedChartData = useMemo(() => {
    if (dailyChartData.length === 0) {
      return [];
    }

    const totalDays = dailyChartData.length;
    const firstDate = moment(dailyChartData[0].X);
    const lastDate = moment(dailyChartData[totalDays - 1].X);
    const totalWeeks = lastDate.diff(firstDate, 'weeks');

    let chartData: ChartDataPointWithDateRange[] = [];

    if (totalDays <= 8) {
      chartData = Object.keys(groupedDataByDay).map((key) => ({
        X: key,
        Y: groupedDataByDay[key].value,
        dateRange: groupedDataByDay[key].dateRange,
      }));
    } else if (totalWeeks < 8) {
      chartData = Object.keys(groupedDataByWeek).map((key) => ({
        X: key,
        Y: groupedDataByWeek[key].value,
        dateRange: groupedDataByWeek[key].dateRange,
      }));
    } else {
      chartData = Object.keys(groupedDataByMonth).map((key) => ({
        X: key,
        Y: groupedDataByMonth[key].value,
        dateRange: groupedDataByMonth[key].dateRange,
      }));
    }

    return chartData;
  }, [dailyChartData, groupedDataByDay, groupedDataByWeek, groupedDataByMonth]);

  return {
    groupedDataByDay,
    groupedDataByWeek,
    groupedDataByMonth,
    chartData: preparedChartData,
  };
};
