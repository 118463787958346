import { gql } from '@apollo/client';
import { useClipboard } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Avatar as MuiAvatar,
  Typography,
} from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { toast } from 'components/common/Toast';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { useUserContext } from 'contexts/users/User.context';
import { useAccessRequestHandlers } from 'features/accessRequest';
import {
  GeneralPermissionSelector,
  ManageUsersInput,
  usePermissionRequestHandler,
  usePermissionUserSearch,
} from 'features/permission';
import {
  ApproveBrandInviteMember,
  SocialListeningPermissionMember,
  SocialListeningPermissionMemberUnselected,
} from 'features/socialListeningPermissions/components';
import {
  BrandInviteMemberFragmentSocialListeningPermissionMemberFragmentDoc,
  BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyViewFragment,
  BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyViewFragmentDoc,
  BrandPermission,
  CreatorFragmentCreatorAvatarWithNameViewFragmentDoc,
  GeneralPermission,
  InternalOrganizationRole,
  PermissionLevel,
  useCreatePermissionRequestForSocialListeningUserPermissionsDialogBodyViewMutation,
  useGetBrandForSocialListeningUserPermissionsDialogBodyViewQuery,
  useGetMyPendingOrganizationAccessRequestsForAdminQuery,
  useGetPendingPermissionRequestsForSocialListeningUserPermissionsDialogBodyViewQuery,
  UserFragmentAvatarGroupFragment,
  UserProfileFragmentSocialListeningPermissionMemberFragment,
  UserProfileFragmentSocialListeningPermissionMemberFragmentDoc,
  UserProfileFragmentSocialListeningUserPermissionsDialogBodyViewFragment,
  useUpdateBrandPermissionsForSocialListeningUserPermissionsDialogBodyViewMutation,
} from 'graphql/generated';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment UserProfileFragmentSocialListeningUserPermissionsDialogBodyView on UserProfileModel {
    ...UserProfileFragmentSocialListeningPermissionMember
  }
  ${UserProfileFragmentSocialListeningPermissionMemberFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyView on BrandInviteMemberModel {
    ...BrandInviteMemberFragmentSocialListeningPermissionMember
  }
  ${BrandInviteMemberFragmentSocialListeningPermissionMemberFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreatePermissionRequestForSocialListeningUserPermissionsDialogBodyView(
    $data: CreatePermissionRequestInput!
  ) {
    createPermissionRequest(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPendingPermissionRequestsForSocialListeningUserPermissionsDialogBodyView(
    $brandId: String!
  ) {
    pendingPermissionRequestsForBrand(brandId: $brandId) {
      id
      user {
        id
        email
        ...UserProfileFragmentSocialListeningPermissionMember
      }
      status
    }
  }
  ${UserProfileFragmentSocialListeningPermissionMemberFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandForSocialListeningUserPermissionsDialogBodyView(
    $brandId: String!
  ) {
    brand(id: $brandId) {
      id
      name
      organizationId
      creators {
        id
        ...CreatorFragmentCreatorAvatarWithNameView
      }
      organization {
        id
        name
      }
      myPermissions
      generalPermission
      permissionLevel
      inviteMembers {
        ...BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyView
      }
    }
  }
  ${BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyViewFragmentDoc}
  ${CreatorFragmentCreatorAvatarWithNameViewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandPermissionsForSocialListeningUserPermissionsDialogBodyView(
    $data: UpdateBrandPermissionsInput!
  ) {
    updateBrandPermissions(data: $data) {
      success
      message
    }
  }
`;

type Props = {
  brandId: string;
  onClose: () => void;
};

export const SocialListeningUserPermissionsDialogBodyView = ({
  brandId,
  onClose,
}: Props) => {
  const { user, isWorkOrganization } = useUserContext();
  const {
    onCheckIsRequestExist,
    myPermissionRequests,
    onApprovePermissionRequest,
    onRejectPermissionRequest,
  } = usePermissionRequestHandler();
  const [requestAlreadyExists, setRequestAlreadyExists] = useState(false);

  useEffect(() => {
    onCheckIsRequestExist({ brandId }).then((exists) => {
      setRequestAlreadyExists(!!exists);
    });
  }, [brandId]);

  const [createPermissionRequest] =
    useCreatePermissionRequestForSocialListeningUserPermissionsDialogBodyViewMutation();
  const { data: pendingPermissionRequestsData } =
    useGetPendingPermissionRequestsForSocialListeningUserPermissionsDialogBodyViewQuery(
      {
        variables: {
          brandId,
        },
      },
    );

  const pendingPermissionRequests =
    pendingPermissionRequestsData?.pendingPermissionRequestsForBrand || [];

  const {
    onRejectOrganizationAccessRequest,
    onApproveOrganizationAccessRequest,
  } = useAccessRequestHandlers();

  const [generalPermission, setGeneralPermission] = useState(
    GeneralPermission.OrganizationMembers,
  );
  const [permissionLevel, setPermissionLevel] = useState(PermissionLevel.Full);

  const [sLBrandInviteMembers, setSLBrandInviteMembers] = useState<
    BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyViewFragment[]
  >([]);

  const [selectedUsers, setSelectedUsers] = useState<
    UserProfileFragmentSocialListeningUserPermissionsDialogBodyViewFragment[]
  >([]);

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [updateBrandPermissions] =
    useUpdateBrandPermissionsForSocialListeningUserPermissionsDialogBodyViewMutation();

  const {
    data: brandData,
    loading,
    refetch,
  } = useGetBrandForSocialListeningUserPermissionsDialogBodyViewQuery({
    variables: {
      brandId,
    },
  });
  const { onCopy } = useClipboard(
    `${
      window.location.origin +
      PlotRoutes.socialListeningRequestAccessLink(brandId)
    }?brandName=${brandData?.brand.name}`,
  );

  const userHasFullAccess = brandData?.brand.myPermissions.includes(
    BrandPermission.Write,
  );

  useEffect(() => {
    if (brandData?.brand) {
      setGeneralPermission(brandData.brand.generalPermission);
      setPermissionLevel(brandData.brand.permissionLevel);
      setSLBrandInviteMembers(brandData.brand.inviteMembers);
    }
  }, [brandData?.brand]);

  const { data: allPendingRequests } =
    useGetMyPendingOrganizationAccessRequestsForAdminQuery({
      skip: user?.role !== InternalOrganizationRole.Admin,
    });

  const brandInviteMemberdPendingRequests = useMemo(() => {
    return (
      allPendingRequests?.myPendingOrganizationAccessRequestsForAdmin.filter(
        (pendingRequest) =>
          brandData?.brand.inviteMembers.some(
            (brandInviteMember) =>
              brandInviteMember.user.email === pendingRequest.user.email,
          ),
      ) || []
    );
  }, [allPendingRequests, brandData]);

  const { suggestedUsers: suggestedNewUsersToAdd } = usePermissionUserSearch({
    searchStr: inputValue,
    existingMembers: sLBrandInviteMembers.map((x) => x.user),
  });

  const [filteredUsersInSL, setFilteredUsersInSL] = useState<
    BrandInviteMemberModelFragmentSocialListeningUserPermissionsDialogBodyViewFragment[]
  >([]);

  const totalMembers = sLBrandInviteMembers.filter(
    (u) =>
      !user?.organization.users
        .filter((user) => user.shouldBeCountedTowardPaywall)
        .some((internalUser) => internalUser.email === u.user.email) &&
      u.user.organization.id === brandData?.brand.organizationId,
  ).length;

  const totalGuests = sLBrandInviteMembers.filter((u) => {
    if (isWorkOrganization) {
      return (
        !user?.organization.externalUsers
          .filter((user) => user.shouldBeCountedTowardPaywall)
          .some((guest) => guest.email === u.user.email) &&
        u.user.organization.id !== brandData?.brand.organizationId
      );
    }

    // everyone is a guests for non-work organization
    return user?.organization.users
      .filter((user) => user.shouldBeCountedTowardPaywall)
      .every((internalUser) => internalUser.email !== u.user.email);
  }).length;

  useEffect(() => {
    if (inputValue) {
      const filteredExistingSLUsers = sLBrandInviteMembers
        .filter(
          (user) =>
            user.user.email.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.user.firstName
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            user.user.lastName.toLowerCase().includes(inputValue.toLowerCase()),
        )
        .slice(0, 3);
      setFilteredUsersInSL(filteredExistingSLUsers);
    }
  }, [inputValue]);

  const removeBrandInviteMember = (email: string) => {
    setSLBrandInviteMembers((prev) =>
      prev.filter((u) => u.user.email !== email),
    );
    setSelectedUsers((prev) => prev.filter((u) => u.email !== email));
  };

  const updateBrandInviteMemberPermission = (
    userId: string,
    permissionLevel: PermissionLevel,
  ) => {
    setSLBrandInviteMembers((prev) => {
      const index = prev.findIndex((u) => u.user.id === userId);
      const newUsers = _.cloneDeep(prev);
      newUsers[index].permissionLevel = permissionLevel;
      return newUsers;
    });
  };

  const toggleUserSelect = (
    user: UserProfileFragmentSocialListeningUserPermissionsDialogBodyViewFragment,
  ) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(selectedUsers.filter((x) => x.email !== user.email));
      setSLBrandInviteMembers((prev) =>
        prev.filter((u) => u.user.email !== user.email),
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
      setSLBrandInviteMembers((prev) => [
        ...prev,
        { id: '', user, permissionLevel: PermissionLevel.Full },
      ]);
    }
    setInputValue('');
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: 'calc(100% - 110px)',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            p: 6,
            pb: 4,
            backgroundColor: '#FAF3EC4D',
            position: 'relative',
          }}
        >
          <ManageUsersInput
            placeholder={userHasFullAccess ? 'Add people...' : ''}
            disabled={!userHasFullAccess}
            searchStr={inputValue}
            inputRef={inputRef}
            onSearchStrUpdate={(text) => {
              setInputValue(text);
            }}
            onRemoveUser={(index) => {
              toggleUserSelect(selectedUsers[index]);
            }}
            users={selectedUsers}
            showInviteButton={false}
            componentProps={{
              tag: { sx: { bgcolor: 'rgba(35, 6, 3, 0.1)' } },
              input: {
                sx: {
                  '& .MuiChip-label': {
                    pl: 1,
                  },
                  '.MuiOutlinedInput-root': {
                    p: `0 !important`,

                    input: {
                      ...theme.typography['headline-xs'],
                      p: '0 !important',
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none !important',
                    },
                  },
                },
              },
            }}
            sx={{
              padding: 0,
            }}
          />
          {!userHasFullAccess && (
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[700]}
              sx={{
                position: 'absolute',
                top: theme.spacing(5),
                left: theme.spacing(5),
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontWeight: 600,
              }}
            >
              <IconBoldInfoCircle size={20} />
              Only people with full access can change permissions
            </Typography>
          )}

          <Tooltip title="Copy link" placement="left" sx={{ zIndex: 1300 }}>
            <IconButton
              onClick={() => {
                onCopy();
                toast({
                  message: 'Link copied',
                  type: 'success',
                });
              }}
              sx={{
                position: 'absolute',
                top: theme.spacing(4),
                right: theme.spacing(4),
                backgroundColor: '#2306030D',
                transform: 'rotate(-45deg)',
                zIndex: 1300,
              }}
            >
              <IconOutlineLink size={16} />
            </IconButton>
          </Tooltip>

          {/* {renderMembers} */}
        </Box>
        <Box sx={{ backgroundColor: '#E3DCD566' }}>
          <Box display="flex" alignItems="center" p={6} pb={0}>
            <Typography
              variant="body-lg"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                p: theme.spacing(1, 2),
                borderRadius: 2,
                gap: 2,
                color: theme.colors?.utility['900'],
                backgroundColor: '#2306030D',
                fontWeight: 600,
              }}
            >
              <MuiAvatar
                src={brandData?.brand.creators?.[0].profilePictureUrl || ''}
                sx={{
                  width: theme.spacing(4),
                  height: theme.spacing(4),
                }}
              />
              {brandData?.brand.name}'s Social Listening
            </Typography>
          </Box>
          {!loading ? (
            <>
              {!inputValue && (
                <Box sx={{ p: 6, pt: 4, minHeight: theme.spacing(87) }}>
                  {!sLBrandInviteMembers.length &&
                    !brandInviteMemberdPendingRequests.length && (
                      <Typography
                        fontWeight={600}
                        variant="body-lg"
                        color={theme.colors?.utility['800']}
                      >
                        No members in this social listening brand
                      </Typography>
                    )}
                  {user && !userHasFullAccess && (
                    <Box
                      display="flex"
                      gap={2}
                      alignItems="center"
                      width="100%"
                      mb={2}
                      pb={4}
                      sx={{
                        borderBottom: `2px solid ${theme.colors?.utility[400]}`,
                      }}
                    >
                      <Avatar
                        user={
                          user as unknown as UserFragmentAvatarGroupFragment
                        }
                        size={36}
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        flex={1}
                      >
                        <Typography
                          variant="body-lg"
                          color={theme.colors?.primary.black}
                          fontWeight={600}
                        >
                          {getFullName(user)}
                        </Typography>
                        <Typography
                          variant="body-lg"
                          color={theme.colors?.utility['900']}
                        >
                          {user.email}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        justifyContent="flex-end"
                      >
                        <Typography
                          textAlign="right"
                          variant="body-lg"
                          color={theme.colors?.utility[800]}
                          fontWeight={600}
                        >
                          Can view
                        </Typography>

                        {!requestAlreadyExists ? (
                          <Typography
                            variant="body-lg"
                            color={theme.colors?.primary.black}
                            fontWeight={600}
                            sx={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              createPermissionRequest({
                                variables: { data: { input: { brandId } } },
                              });
                              setRequestAlreadyExists(true);
                            }}
                          >
                            Request full access
                          </Typography>
                        ) : (
                          <Typography
                            variant="body-lg"
                            color={theme.colors?.primary.black}
                            fontWeight={600}
                          >
                            Request sent
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  {pendingPermissionRequests.length > 0 &&
                    userHasFullAccess && (
                      <Box sx={{}}>
                        <Box
                          sx={{
                            mb: 2,
                            pb: 2,
                            borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            variant="body-lg"
                            color={theme.colors?.utility['800']}
                          >
                            {pendingPermissionRequests.length} person requested
                            access
                          </Typography>

                          {pendingPermissionRequests.map((pendingRequest) => {
                            return (
                              <ApproveBrandInviteMember
                                brandInviteMember={{
                                  id: '',
                                  permissionLevel: PermissionLevel.Full,
                                  user: pendingRequest.user as unknown as UserProfileFragmentSocialListeningPermissionMemberFragment,
                                }}
                                onApprove={async (permissionLevel) => {
                                  await onApprovePermissionRequest({
                                    permissionRequestId: pendingRequest.id,
                                    input: { permissionLevel },
                                  });
                                  refetch();
                                }}
                                onReject={async () => {
                                  await onRejectPermissionRequest({
                                    permissionRequestId: pendingRequest.id,
                                  });
                                  refetch();
                                }}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    )}

                  {sLBrandInviteMembers.map((brandInviteMember) => (
                    <Box key={brandInviteMember.user.email} sx={{ mb: 2 }}>
                      <SocialListeningPermissionMember
                        brandInviteMember={brandInviteMember}
                        slOrgId={brandData?.brand.organizationId || ''}
                        readonly={!userHasFullAccess}
                        onPermissionChange={(
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          brandInviteMember: {
                            user: UserProfileFragmentSocialListeningPermissionMemberFragment;
                            permissionLevel: PermissionLevel;
                          },
                          remove?: boolean,
                        ) => {
                          if (remove) {
                            removeBrandInviteMember(
                              brandInviteMember.user.email,
                            );
                          } else {
                            updateBrandInviteMemberPermission(
                              brandInviteMember.user.id,
                              brandInviteMember.permissionLevel,
                            );
                          }
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
              {!!inputValue && (
                <Box p={6} pt={4} minHeight={theme.spacing(87)}>
                  {!!filteredUsersInSL.length && (
                    <>
                      <Typography
                        my={2}
                        fontWeight={600}
                        variant="body-lg"
                        color={theme.colors?.utility['800']}
                      >
                        In this social listening brand
                      </Typography>
                      {(filteredUsersInSL || []).map((brandInviteMember) => (
                        <SocialListeningPermissionMember
                          brandInviteMember={brandInviteMember}
                          readonly={!userHasFullAccess}
                          slOrgId={brandData?.brand.organizationId || ''}
                          onPermissionChange={(
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            brandInviteMember: {
                              user: UserProfileFragmentSocialListeningPermissionMemberFragment;
                              permissionLevel: PermissionLevel;
                            },
                            remove?: boolean,
                          ) => {
                            if (remove) {
                              removeBrandInviteMember(
                                brandInviteMember.user.email,
                              );
                            } else {
                              updateBrandInviteMemberPermission(
                                brandInviteMember.user.id,
                                brandInviteMember.permissionLevel,
                              );
                            }
                          }}
                        />
                      ))}
                    </>
                  )}

                  {!!suggestedNewUsersToAdd.length && (
                    <>
                      <Typography
                        my={2}
                        fontWeight={600}
                        variant="body-lg"
                        color={theme.colors?.utility['800']}
                      >
                        Not in this social listening brand
                      </Typography>
                      {suggestedNewUsersToAdd
                        .slice(0, 3)
                        .map((suggestedUser) => (
                          <SocialListeningPermissionMemberUnselected
                            key={suggestedUser?.email}
                            slOrgId={brandData?.brand.organizationId || ''}
                            user={suggestedUser}
                            isSelected={false}
                            onToggleSelect={() => {
                              toggleUserSelect(suggestedUser);
                            }}
                          />
                        ))}
                    </>
                  )}

                  {!suggestedNewUsersToAdd.length &&
                    !filteredUsersInSL.length && (
                      <Typography
                        fontWeight={600}
                        variant="body-lg"
                        color={theme.colors?.utility['800']}
                      >
                        Keep typing a valid email...
                      </Typography>
                    )}
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                minHeight: theme.spacing(125),
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backdropFilter:
            permissionLevel === PermissionLevel.Full ? 'blur(20px)' : 'none',
        }}
      >
        {generalPermission && (
          <GeneralPermissionSelector
            key={generalPermission + permissionLevel}
            hideSetDefault
            readonly={!userHasFullAccess}
            entityType="collection"
            organizationId={brandData?.brand.organizationId || ''}
            organizationName={brandData?.brand.organization?.name}
            initialGeneralPermission={generalPermission}
            initialPermissionLevel={permissionLevel || PermissionLevel.View}
            onGeneralPermissionChange={(generalPermission, permissionLevel) => {
              setGeneralPermission(generalPermission);
              setPermissionLevel(permissionLevel || PermissionLevel.Full);
            }}
            visiblePermissionLevels={[
              PermissionLevel.Full,
              PermissionLevel.View,
            ]}
            componentProps={{
              sx: {
                bgcolor: 'rgba(250, 243, 236, 0.85)',
                backdropFilter: 'blur(20px)',
                p: theme.spacing(4, 6),
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius:
                  permissionLevel === PermissionLevel.Full
                    ? 0
                    : theme.spacing(0, 0, 6, 6),
              },
            }}
          />
        )}

        <Button
          disabled={!userHasFullAccess}
          variant="primary-alt"
          fullWidth
          onClick={async () => {
            await updateBrandPermissions({
              variables: {
                data: {
                  brandId,
                  generalPermission: {
                    generalPermission,
                    permissionLevel,
                  },
                  members: sLBrandInviteMembers
                    .filter((x) => !x.user.isDisabled)
                    .map((member) => {
                      return {
                        email: member.user.email,
                        permission: member.permissionLevel,
                      };
                    }),
                },
              },
              onCompleted: () => {
                refetch();
              },
            });
            onClose();
          }}
          sx={{
            height: 42,
            borderRadius: theme.spacing(0, 0, 6, 6),
            opacity: 1,
          }}
          startIcon={false ? <CircularProgress size="1rem" /> : null}
        >
          Update
        </Button>
      </Box>
    </>
  );
};
