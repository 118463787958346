import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useGetBrandsParsedHoursQuery } from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { Tooltip } from 'components/common/Tooltip';
import { generateMinimumRepeatingSetOfIntegers } from 'utils/number';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandsParsedHours($totalHoursParsedData: BrandHoursParsedInput) {
    me {
      id
      organization {
        id
        billing {
          id
          brandHoursParseLimit
        }
        socialListeningBrands {
          id
          name
          totalHoursParsedCount(data: $totalHoursParsedData)
        }
      }
    }
  }
`;

const colors = [
  theme.colors?.utility['purple-2'],
  theme.colors?.utility['blue-4'],
  theme.colors?.utility['teal-2'],
  theme.colors?.utility['green-2'],
  theme.colors?.utility['yellow-2'],
  theme.colors?.utility['orange-2'],
  theme.colors?.utility['pink-2'],
];

export const BrandParsedHours = () => {
  const { data: parsedHoursData } = useGetBrandsParsedHoursQuery({
    variables: {
      totalHoursParsedData: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      },
    },
  });

  const totalHoursParsed = useMemo(() => {
    if (!parsedHoursData) {
      return 0;
    }

    return parsedHoursData.me.organization.socialListeningBrands.reduce(
      (totalHoursParsed, brand) => {
        return totalHoursParsed + brand.totalHoursParsedCount;
      },
      0,
    );
  }, [parsedHoursData]);

  const totalHoursLimit =
    parsedHoursData?.me.organization.billing.brandHoursParseLimit || 0;

  const brandPercentages = useMemo(() => {
    if (!parsedHoursData) {
      return [];
    }

    // The indexes for colors might repeat in the case
    // there are more brands than colors.
    // Calling this function guarantee the amount of
    // repeated indexes is kept to the minimum possible
    const randomColorIndexes = generateMinimumRepeatingSetOfIntegers(
      colors.length,
      parsedHoursData.me.organization.socialListeningBrands.length,
    );

    return parsedHoursData.me.organization.socialListeningBrands.map(
      (brand, i) => {
        const colorIndex = randomColorIndexes[i];

        return {
          ...brand,
          hoursParsed: brand.totalHoursParsedCount,
          percentage: brand.totalHoursParsedCount / (totalHoursLimit || 1),
          color: colors[colorIndex],
        };
      },
    );
  }, [parsedHoursData, totalHoursLimit]);

  return (
    <Box>
      <Typography
        variant="headline-xs"
        color={theme.colors?.utility[700]}
        marginBottom={1}
        component="p"
      >
        Total hours parsed: {totalHoursParsed}/{totalHoursLimit}
      </Typography>
      <Box
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
          height: 18,
          width: 240,
          display: 'flex',
          backgroundColor: theme.colors?.utility[300],
        }}
      >
        {brandPercentages.map((brand) => (
          <Box
            key={brand.id}
            sx={{
              height: '100%',
              width: `${brand.percentage * 100}%`,
              backgroundColor: brand.color,
            }}
          >
            <Tooltip
              title={`${brand.name}: ${brand.hoursParsed} hour${
                brand.hoursParsed > 1 ? 's' : ''
              }`}
            >
              <Box height="100%" width="100%" />
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
