import { Box, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { StyledSlider } from '../styles';

interface FilterByMinimumPlayCountProps {
  onToggle: (value: number) => void;
  selectedMinimumPlayCount: number;
  renderTitle?: () => React.ReactNode;
  type?: 'accordion' | 'normal';
}

export const FilterByMinimumPlayCount = ({
  onToggle,
  selectedMinimumPlayCount = 0,
  renderTitle,
  type = 'accordion',
}: FilterByMinimumPlayCountProps) => {
  const renderDefaultTitle = useMemo(() => {
    return (
      <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
        Minimum Play Count
      </Typography>
    );
  }, []);

  const renderOptions = useMemo(() => {
    return (
      <Box
        sx={{
          width: '100%',
          ...(type === 'accordion' && { pl: 4, pr: 2 }),
          '& .MuiSlider-mark': {
            background: 'transparent',
          },
        }}
      >
        <StyledSlider
          defaultValue={0}
          valueLabelDisplay="auto"
          step={10000}
          min={0}
          max={100_000_000}
          marks={[
            { value: 0, label: '0' },
            { value: 100000, label: '100k' },
            { value: 1000000, label: '1M' },
            { value: 5000000, label: '5M' },
            { value: 10000000, label: '10M' },
            { value: 100000000, label: '100M' },
          ]}
          onChange={(_, value) => {
            if (typeof value === 'number') {
              onToggle(value);
            }
          }}
          value={selectedMinimumPlayCount}
          valueLabelFormat={(value) => `${formatBigNumber(value)}`}
        />
      </Box>
    );
  }, [type, selectedMinimumPlayCount, onToggle]);

  if (type === 'normal') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {renderTitle?.() ?? renderDefaultTitle}
        <Box>{renderOptions}</Box>
      </Box>
    );
  }

  const Icon =
    socialListeningFilterIconMap[SocialListeningFiltersKey.MinimumPlayCount];

  return (
    <CollapsibleSection
      sx={{
        padding: theme.spacing(2, 3), // To match the padding of MenuItem
        borderRadius: 3,
        ':hover': {
          background: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      renderHeaderTitle={() =>
        renderTitle?.() ?? (
          <Box display="flex" alignItems="center" gap={2} width="100%">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <Icon size={16} />
            </Box>
            {renderDefaultTitle}
          </Box>
        )
      }
      divider={false}
    >
      {renderOptions}
    </CollapsibleSection>
  );
};
