import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import moment from 'moment';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useCallback, useMemo } from 'react';
import { theme } from 'styles/theme';

export const CUSTOM_RANGE_LABEL = 'Custom Range';

interface FilterByPeriodProps {
  onToggle: (data?: { label: string; range: (Date | null)[] }) => void;
  selectedPeriod: {
    label: string;
    startDate: Date;
    endDate: Date;
  };
  renderTitle?: () => React.ReactNode;
  type?: 'accordion' | 'normal';
}

export const FilterByPeriod = ({
  onToggle,
  selectedPeriod,
  renderTitle,
  type = 'accordion',
}: FilterByPeriodProps) => {
  const {
    isOpen: dateRangeOpen,
    onOpen: onDateRangeOnOpen,
    onClose: onDateRangeOnClose,
  } = useDisclosure();

  const onSelectedPeriodLabel = useCallback(
    (
      selectedPeriodLabel: string | null,
      startDate: Date | null,
      endDate: Date | null,
    ) => {
      if (selectedPeriodLabel) {
        onToggle({
          label: selectedPeriodLabel,
          range: [startDate, endDate],
        });
      } else {
        onToggle();
      }
    },
    [onToggle],
  );
  const selectedPeriodLabel = selectedPeriod.label;
  const selectedPeriodCustomStartDate = selectedPeriod.startDate;
  const selectedPeriodCustomEndDate = selectedPeriod.endDate;

  const Icon = socialListeningFilterIconMap[SocialListeningFiltersKey.Period];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Period</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">
          Period{' '}
          {selectedPeriod.label && (
            <Typography variant="subhead-lg" color={theme.colors?.utility[600]}>
              ({selectedPeriod.label})
            </Typography>
          )}
        </Typography>
      </Box>
    );
  }, [Icon, renderTitle, selectedPeriod.label, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      dropDownOptions={[
        {
          label: <Typography variant="subhead-lg">Today</Typography>,
          isChecked: selectedPeriodLabel === 'Today',
          onClick: () => {
            if (selectedPeriodLabel === 'Today') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Today',
                moment().toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: <Typography variant="subhead-lg">Last 7 days</Typography>,
          isChecked: selectedPeriodLabel === 'Last 7 days',
          onClick: () => {
            if (selectedPeriodLabel === 'Last 7 days') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Last 7 days',
                moment().subtract(7, 'day').toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: <Typography variant="subhead-lg">Last 30 days</Typography>,
          isChecked: selectedPeriodLabel === 'Last 30 days',
          onClick: () => {
            if (selectedPeriodLabel === 'Last 30 days') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Last 30 days',
                moment().subtract(30, 'day').toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: (
            <Typography variant="subhead-lg">
              This year ({moment().startOf('year').year()})
            </Typography>
          ),
          isChecked: selectedPeriodLabel === 'This year',
          onClick: () => {
            if (selectedPeriodLabel === 'This year') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'This year',
                moment().startOf('year').toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: (
            <Typography variant="subhead-lg">
              Last year ({moment().subtract(1, 'year').year()})
            </Typography>
          ),
          isChecked: selectedPeriodLabel === 'Last year',
          onClick: () => {
            if (selectedPeriodLabel === 'Last year') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Last year',
                moment().subtract(1, 'year').startOf('year').toDate(),
                moment().subtract(1, 'year').endOf('year').toDate(),
              );
            }
          },
        },
        {
          onClick: onDateRangeOnOpen,
          label: (
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subhead-lg">
                  {selectedPeriodLabel === CUSTOM_RANGE_LABEL
                    ? `${moment(selectedPeriodCustomStartDate).format(
                        'DD MMM',
                      )} - 
                                  ${moment(selectedPeriodCustomEndDate).format(
                                    'DD MMM',
                                  )}`
                    : CUSTOM_RANGE_LABEL}
                </Typography>
                <IconLinearArrowRight
                  size={16}
                  style={{
                    position: 'absolute',
                    right: 16,
                    marginTop: 2,
                  }}
                />
              </Box>
              <DateRangePicker
                value={[
                  moment(selectedPeriodCustomStartDate),
                  moment(selectedPeriodCustomEndDate),
                ]}
                calendars={1}
                open={dateRangeOpen}
                onClose={onDateRangeOnClose}
                onChange={(dates) => {
                  if (dates.length === 2 && dates[0] && dates[1]) {
                    onSelectedPeriodLabel(
                      CUSTOM_RANGE_LABEL,
                      dates[0].toDate(),
                      dates[1].toDate(),
                    );
                  }
                }}
                sx={{ '& .MuiTypography-root': { display: 'none' } }}
                slots={{
                  // eslint-disable-next-line
                  textField: () => null,
                }}
                slotProps={{
                  actionBar: {
                    actions: [],
                  },
                }}
              />
            </Box>
          ),
          isChecked: selectedPeriodLabel === CUSTOM_RANGE_LABEL,
        },
      ]}
    />
  );
};
