import { Box, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

export enum OrganicPaidOptions {
  ORGANIC = 'Organic',
  PAID = 'Paid',
}

interface FilterByOrganicPaidProps {
  onToggle: (isOrganic?: boolean) => void;
  options: OrganicPaidOptions[];
  isOrganic?: boolean;
  renderTitle?: () => React.ReactNode;
  type?: 'accordion' | 'normal';
}

export const FilterByOrganicPaid = ({
  onToggle,
  options,
  isOrganic,
  renderTitle,
  type = 'accordion',
}: FilterByOrganicPaidProps) => {
  const [selectedOptions, setSelectedOption] = useState(
    typeof isOrganic === 'boolean'
      ? isOrganic
        ? [OrganicPaidOptions.ORGANIC]
        : [OrganicPaidOptions.PAID]
      : [],
  );

  const Icon =
    socialListeningFilterIconMap[SocialListeningFiltersKey.OrganicPaid];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Organic/Paid</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">Organic/Paid</Typography>
      </Box>
    );
  }, [Icon, renderTitle, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      dropDownOptions={options.map((option) => ({
        label: option,
        onClick: () => {
          const newSelectedOptions = selectedOptions.includes(option)
            ? selectedOptions.filter((o) => o !== option)
            : [...selectedOptions, option];
          setSelectedOption(newSelectedOptions);
          const length = newSelectedOptions.length;
          onToggle(
            length === options.length || length === 0
              ? undefined
              : newSelectedOptions.includes(OrganicPaidOptions.ORGANIC),
          );
        },
        isChecked: selectedOptions.includes(option),
      }))}
    />
  );
};
