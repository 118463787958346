import { Box } from '@mui/material';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningFilterByPlatformsButton,
  SocialMediaListeningFilterBySourcesButton,
  SocialMediaListeningFilterByUserButton,
} from 'features/socialMediaListening';
import { SocialMediaListeningFilterByTopicsButton } from 'features/socialMediaListening/components/filterByTopicsButton';
import { PlotFeature, SocialPostCommentsFilters } from 'graphql/generated';

type SocialMediaListeningAnalyticsOutboundInteractionFiltersProps = {
  filters: SocialPostCommentsFilters;
  setFilters: (filters: SocialPostCommentsFilters) => void;
  componentProps?: {
    buttonColors?: string;
  };
};

export const SocialMediaListeningAnalyticsOutboundInteractionFilters = (
  props: SocialMediaListeningAnalyticsOutboundInteractionFiltersProps,
) => {
  const { filters, setFilters, componentProps = {} } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
    >
      {isFeatureEnabled(PlotFeature.ManualOutboundUpload) && (
        <SocialMediaListeningFilterBySourcesButton
          selectedSources={filters.sources || []}
          onChange={(sources) => {
            setFilters({ ...filters, sources });
          }}
        />
      )}
      <SocialMediaListeningFilterByTopicsButton
        selectedTopicIds={filters.topicIds || []}
        onChange={(topicIds) => {
          setFilters({ ...filters, topicIds });
        }}
      />
      <SocialMediaListeningFilterByUserButton
        selectedUsers={filters.responderIds || []}
        onChange={(userIds) => {
          setFilters({
            ...filters,
            responderIds: userIds.length > 0 ? userIds : undefined,
          });
        }}
        componentProps={{
          sx: {
            bgcolor: componentProps?.buttonColors,
            ':hover': {
              bgcolor: componentProps?.buttonColors,
            },
          },
        }}
      />

      <SocialMediaListeningFilterByPlatformsButton
        selectedPlatforms={filters.platforms || []}
        onChange={(platforms) => {
          setFilters({ ...filters, platforms });
        }}
        componentProps={{
          sx: {
            bgcolor: componentProps?.buttonColors,
            ':hover': {
              bgcolor: componentProps?.buttonColors,
            },
          },
        }}
      />

      <SocialMediaListeningFilterByDateRangeButton
        selectedDateRange={
          filters.dateRange
            ? [filters.dateRange.startDate, filters.dateRange.endDate]
            : [null, null]
        }
        onChange={(dateRange) => {
          setFilters({
            ...filters,
            dateRange: { startDate: dateRange[0], endDate: dateRange[1] },
          });
        }}
        componentProps={{
          sx: {
            bgcolor: componentProps?.buttonColors,
            ':hover': {
              bgcolor: componentProps?.buttonColors,
            },
          },
        }}
      />
    </Box>
  );
};
