import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { theme } from 'styles/theme';

type RenderNextButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  actionLabel?: React.ReactNode;
  actionBtnEndIcon?: React.ReactNode;
  onNextClick: () => void;
  helperText?: React.ReactNode;
};

export const RenderNextButton = ({
  disabled,
  loading,
  onNextClick,
  actionBtnEndIcon,
  actionLabel,
  helperText,
}: RenderNextButtonProps) => {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        // Prevent the shortcut from triggering when the user is focusing on input elements
        const activeElement = document?.activeElement as HTMLElement;
        if (
          (activeElement &&
            (activeElement.tagName === 'INPUT' ||
              activeElement.tagName === 'TEXTAREA' ||
              activeElement.isContentEditable)) || // exclude text editor
          document?.activeElement?.className.includes('ProseMirror')
        ) {
          return;
        }

        onNextClick();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onNextClick]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        position: 'fixed',
        bottom: theme.spacing(20),
        gap: 3,
        color: theme.colors?.primary.parchment,
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <Button
        disabled={disabled}
        endIcon={
          loading ? (
            <CircularProgress
              size={theme.spacing(4)}
              sx={{
                color: theme.colors?.primary.black,
              }}
            />
          ) : (
            actionBtnEndIcon || (
              <Typography
                variant="body-xs"
                component="span"
                p={theme.spacing(0.5, 1)}
                sx={{
                  backgroundColor: theme.colors?.utility[400],
                  mx: 1,
                  border: `2px solid ${theme.colors?.utility[600]}`,
                  borderRadius: theme.spacing(2),
                  fontWeight: 600,
                  fontSize: `${theme.spacing(3)} !important`,
                  color: theme.colors?.utility[800],
                }}
              >
                ⏎
              </Typography>
            )
          )
        }
        sx={{
          backgroundColor: theme.colors?.utility[300],
          borderRadius: `${theme.spacing(35)} !important`,
          padding: theme.spacing(2, 4),
          color: `${theme.colors?.primary.black} !important`,
          ...theme.typography['headline-md'],
          ':hover': {
            backgroundColor: theme.colors?.utility[300],
          },
          opacity: disabled ? 0.3 : 1,
        }}
        onClick={onNextClick}
      >
        {actionLabel || 'Next'}
      </Button>
      {helperText}
    </Box>
  );
};
