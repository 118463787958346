import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTimer1 } from 'components/icons/components/bold/IconBoldTimer1';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { SocialPostCommentCardView } from 'features/socialPostComment';
import {
  SocialPostFragmentSocialPostThumbnailFragmentDoc,
  useGetManuallyParsedSocialPostTrackersForManuallyParsedSocialPostTrackerDialogViewQuery,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

// We'll be reusing the SocialPostCommentCardView component, so we'll also reuse the fragments from socialPostComment feature
// eslint-disable-next-line
gql`
  query GetManuallyParsedSocialPostTrackersForManuallyParsedSocialPostTrackerDialogView(
    $filters: ManuallyParsedSocialPostTrackerFilters!
    $after: String
  ) {
    manuallyParsedSocialPostTrackers(filters: $filters, after: $after) {
      data {
        id
        socialPost {
          id
          ...SocialPostFragmentSocialPostThumbnail
        }
        parsedByUser {
          id
          ...UserFragmentAvatarGroup
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SocialPostFragmentSocialPostThumbnailFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type ManuallyParsedSocialPostTrackerDialogViewProps = {
  brandId: string;
};

export const ManuallyParsedSocialPostTrackerDialogView = (
  props: ManuallyParsedSocialPostTrackerDialogViewProps,
) => {
  const { brandId } = props;

  const {
    isOpen: isDialogOpen,
    onOpen: openDialog,
    onClose: closeDialog,
  } = useDisclosure();

  const { data, refetch, fetchMore } =
    useGetManuallyParsedSocialPostTrackersForManuallyParsedSocialPostTrackerDialogViewQuery(
      {
        variables: {
          filters: {
            brandIds: [brandId],
            hasFoundBrandCreatorComment: false,
          },
        },
        fetchPolicy: 'cache-and-network',
      },
    );
  const manuallyParsedSocialPostTrackers =
    data?.manuallyParsedSocialPostTrackers.data || [];
  const hasNextPage =
    data?.manuallyParsedSocialPostTrackers.pageInfo.hasNextPage;
  const endCursor = data?.manuallyParsedSocialPostTrackers.pageInfo.endCursor;

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        filters: {
          brandIds: [brandId],
          hasFoundBrandCreatorComment: false,
        },
        after: endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          manuallyParsedSocialPostTrackers: {
            data: [
              ...prev.manuallyParsedSocialPostTrackers.data,
              ...fetchMoreResult.manuallyParsedSocialPostTrackers.data,
            ],
            pageInfo: fetchMoreResult.manuallyParsedSocialPostTrackers.pageInfo,
          },
        };
      },
    });
  };

  // Refetch the data when the dialog is opened
  useEffect(() => {
    if (isDialogOpen) {
      refetch();
    }
  }, [isDialogOpen]); // eslint-disable-line

  if (manuallyParsedSocialPostTrackers.length === 0) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconBoldTimer1
            size={16}
            style={{
              color: theme.colors?.utility[600],
            }}
          />
          <Typography variant="subhead-xl" color={theme.colors?.utility[600]}>
            Searching for comments in {manuallyParsedSocialPostTrackers.length}{' '}
            uploaded link
            {manuallyParsedSocialPostTrackers.length > 1 ? 's' : ''}.
          </Typography>
        </Box>
        <Box component="button" onClick={openDialog}>
          <Typography variant="subhead-xl" color={theme.colors?.primary.black}>
            View Status
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        PaperProps={{
          sx: {
            height: '100%',
            width: '100%',
            maxWidth: '95%',
            borderRadius: 6,
            border: 'none',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: '#FAF3ECCC',
            backdropFilter: 'blur(4px)',
          },
        }}
        onClose={closeDialog}
      >
        <Box
          sx={{
            bgcolor: theme.colors?.primary.parchment,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'left',
            overflow: 'hidden',
            height: theme.spacing(18),
            padding: theme.spacing(6),
            borderBottom: `2px solid #2306031A`,
            flexShrink: 0,
          }}
        >
          <Box
            onClick={closeDialog}
            sx={{
              display: 'flex',
              gap: theme.spacing(4),
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <IconOutlineArrowLeft
              size={24}
              color={theme.colors?.primary.black}
            />
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              Posts added via link upload
            </Typography>
          </Box>

          <IconButton onClick={closeDialog}>
            <IconBoldCloseCircle color={theme.colors?.utility[700]} size={24} />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            padding: theme.spacing(6),
            overflow: 'hidden',
          }}
        >
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <InfiniteScroll
              hasMore={hasNextPage}
              loadMore={() => fetchNextPage()}
              initialLoad={false}
              threshold={1024}
              useWindow={false}
              loader={
                <Box
                  key={0}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 3,
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: theme.colors?.primary.maroon }}
                  />
                </Box>
              }
            >
              <Grid container spacing={8}>
                {manuallyParsedSocialPostTrackers.map((tracker) => {
                  return (
                    <Grid item key={tracker.id} xs={12} sm={6} md={4} xl={3}>
                      <SocialPostCommentCardView
                        socialPostComment={{
                          // Constructing a dummy social post comment object to be used as placeholder
                          id: tracker.socialPost.id,
                          socialPost: {
                            ...tracker.socialPost,
                            brandSocialPostEngagement: {
                              id: '',
                              respondedByUser: tracker.parsedByUser,
                            },
                          },
                          platformCreateTime: null,
                          childCommentCount: 0,
                          text: 'Loading comment...',
                          diggCount: 0,
                        }}
                        componentsProps={{
                          text: {
                            sx: {
                              color: theme.colors?.utility[600],
                              fontStyle: 'italic',
                            },
                          },
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
