import { Box, Typography } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconLinearFilter } from 'components/icons/components/linear/IconLinearFilter';
import {
  BrandSocialPostEngagementStatus,
  ExtendedSocialPostTypeForFilter,
  FollowersType,
  Platform,
  Sentiment,
} from 'graphql/generated';
import { SocialListeningFiltersKey } from 'pages/socialMediaListening/constant';
import { useCallback, useMemo } from 'react';
import { theme } from 'styles/theme';
import { FilterOption } from '../types';
import { FilterByFollowers } from './followers';
import { FilterByMinimumPlayCount } from './minimumPlayCount';
import { FilterByOrganicPaid, OrganicPaidOptions } from './organicPaid';
import { FilterByPeriod } from './period';
import { FilterByPlatforms } from './platforms';
import { FilterBySentiments } from './sentiments';
import { FilterByStatuses } from './statuses';
import { FilterByTopicRelevancy } from './topicRelevancy';

export interface FilterByProps {
  selectedFilters: {
    [key in SocialListeningFiltersKey]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number
      | ExtendedSocialPostTypeForFilter[];
  };
  filterByOptions: FilterOption<any>[];
  onFilterUpdated: (filters: {
    [key in SocialListeningFiltersKey]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number
      | ExtendedSocialPostTypeForFilter[];
  }) => void;
}

export const FilterBy = ({
  filterByOptions,
  onFilterUpdated,
  selectedFilters,
}: FilterByProps) => {
  const getOptionComponent = useCallback(
    (option: FilterOption<any>) => {
      switch (option.key) {
        case SocialListeningFiltersKey.Platforms: {
          return (
            <FilterByPlatforms
              onChange={({ selectedPlatforms, selectedTypes }) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.Platforms]: selectedPlatforms,
                  [SocialListeningFiltersKey.Types]: selectedTypes,
                });
              }}
              selectedPlatforms={
                selectedFilters[
                  SocialListeningFiltersKey.Platforms
                ] as unknown as Platform[]
              }
              selectedTypes={
                selectedFilters[
                  SocialListeningFiltersKey.Types
                ] as unknown as ExtendedSocialPostTypeForFilter[]
              }
            />
          );
        }
        case SocialListeningFiltersKey.Followers: {
          return (
            <FilterByFollowers
              onToggle={(selectedFollowers) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.Followers]: selectedFollowers,
                });
              }}
              options={option.options as unknown as FollowersType[]}
              selectedOptions={
                selectedFilters[
                  SocialListeningFiltersKey.Followers
                ] as unknown as FollowersType[]
              }
            />
          );
        }
        case SocialListeningFiltersKey.Sentiments: {
          return (
            <FilterBySentiments
              onToggle={(selectedSentiments) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.Sentiments]: selectedSentiments,
                });
              }}
              options={option.options as unknown as Sentiment[]}
              selectedOptions={
                selectedFilters[
                  SocialListeningFiltersKey.Sentiments
                ] as unknown as Sentiment[]
              }
            />
          );
        }
        case SocialListeningFiltersKey.Statuses: {
          return (
            <FilterByStatuses
              onToggle={(selectedStatuses) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.Statuses]: selectedStatuses,
                });
              }}
              options={
                option.options as unknown as BrandSocialPostEngagementStatus[]
              }
              selectedOptions={
                selectedFilters[
                  SocialListeningFiltersKey.Statuses
                ] as unknown as BrandSocialPostEngagementStatus[]
              }
            />
          );
        }
        case SocialListeningFiltersKey.Period: {
          const selectedPeriod =
            selectedFilters[SocialListeningFiltersKey.Period];
          return (
            <FilterByPeriod
              selectedPeriod={{
                label: selectedPeriod?.[0],
                startDate: selectedPeriod?.[1],
                endDate: selectedPeriod?.[2],
              }}
              onToggle={(selectedPeriod) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.Period]: selectedPeriod
                    ? [selectedPeriod.label, ...selectedPeriod.range]
                    : [],
                });
              }}
            />
          );
        }
        case SocialListeningFiltersKey.TopicRelevancy: {
          return (
            <FilterByTopicRelevancy
              selectedTopicRelevancy={
                (selectedFilters[
                  SocialListeningFiltersKey.TopicRelevancy
                ] as any) || 0.5
              }
              onToggle={(value) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.TopicRelevancy]: value,
                });
              }}
            />
          );
        }
        case SocialListeningFiltersKey.OrganicPaid: {
          return (
            <FilterByOrganicPaid
              options={option.options as unknown as OrganicPaidOptions[]}
              isOrganic={
                selectedFilters[SocialListeningFiltersKey.OrganicPaid] as
                  | boolean
                  | undefined
              }
              onToggle={(isOrganic) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.OrganicPaid]: isOrganic,
                });
              }}
            />
          );
        }
        case SocialListeningFiltersKey.MinimumPlayCount: {
          return (
            <FilterByMinimumPlayCount
              selectedMinimumPlayCount={
                selectedFilters[
                  SocialListeningFiltersKey.MinimumPlayCount
                ] as unknown as number
              }
              onToggle={(value) => {
                onFilterUpdated({
                  ...selectedFilters,
                  [SocialListeningFiltersKey.MinimumPlayCount]: value,
                });
              }}
            />
          );
        }
        default: {
          return null;
        }
      }
    },
    [onFilterUpdated, selectedFilters],
  );

  const renderOptions = useMemo(() => {
    return filterByOptions.map((option) => getOptionComponent(option));
  }, [filterByOptions, getOptionComponent]);

  if (!renderOptions.length) {
    return null;
  }

  return (
    <MoreMenuItem
      disableRipple
      label={
        <Box display="flex" alignItems="center" gap={2} sx={{ width: '100%' }}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              p: 2,
              backgroundColor: 'rgba(35, 6, 3, 0.05)',
              borderRadius: theme.spacing(1),
            }}
          >
            <IconLinearFilter size={16} />
          </Box>
          <Box display="flex" flex={1}>
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              fontWeight={500}
            >
              Filter By
            </Typography>
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          minWidth: 270,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          maxHeight: 420,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 0,
          },
        }}
      >
        {renderOptions}
      </Box>
    </MoreMenuItem>
  );
};
