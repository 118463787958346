import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Chip,
  ChipProps,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { ForwardedRef, forwardRef, useEffect, useId, useState } from 'react';
import {
  InputHorizontalAutosize,
  InputHorizontalAutosizeProps,
} from '../InputHorizontalAutosize';
import { Tooltip } from '../Tooltip';

export type EditableChipProps = ChipProps & {
  isInEditModeByDefault?: boolean;
  componentsProps?: {
    input?: Pick<
      InputHorizontalAutosizeProps,
      'sx' | 'minWidth' | 'placeholder'
    >;
    icon?: {
      size?: number;
    };
  };
  onSave?: (value: string) => void;
  onCancel?: () => void;
};

/**
 * Customized Chip component with an editable state.
 */
export const EditableChip = forwardRef(
  (props: EditableChipProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      isInEditModeByDefault = false,
      label,
      componentsProps = {},
      onSave,
      onCancel,
      onDelete,
      ...rest
    } = props;

    const {
      isOpen: isEditing,
      onOpen: enableEditing,
      onClose: disableEditing,
    } = useDisclosure({
      defaultIsOpen: isInEditModeByDefault,
    });

    const id = useId();
    const [internalValue, setInternalValue] = useState((label as string) ?? '');

    useEffect(() => {
      if (typeof label === 'string') {
        setInternalValue(label);
      }
    }, [label, isEditing]);

    return (
      <Tooltip
        title="Click to edit"
        {...(!isEditing
          ? {}
          : {
              // Need this to force re-render when toggle editing
              // otherwise, the tooltip will be stuck in visible state
              key: String(isEditing),
              open: false,
              disableFocusListener: true,
              disableHoverListener: true,
              disableTouchListener: true,
            })}
      >
        <Chip
          {...rest}
          ref={ref}
          label={
            !isEditing ? (
              label
            ) : (
              <ClickAwayListener onClickAway={disableEditing}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <InputHorizontalAutosize
                    minWidth={40}
                    {...componentsProps.input}
                    name={id}
                    sx={{
                      ...componentsProps.input?.sx,
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      resize: 'none',
                      padding: 0,
                    }}
                    value={internalValue}
                    onChange={(e) => setInternalValue(e.target.value)}
                    onFocus={(e) => e.target.select()}
                    autoFocus
                    autoComplete="off"
                    autoCorrect="off"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === 'Enter') {
                        onSave?.(internalValue);
                        disableEditing();
                      } else if (e.key === 'Escape') {
                        onCancel?.();
                        disableEditing();
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      sx={{
                        p: 0,
                        color: 'inherit',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSave?.(internalValue);
                        disableEditing();
                      }}
                    >
                      <IconBoldTickCircle
                        size={componentsProps?.icon?.size ?? 12}
                        style={{
                          color: 'inherit',
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{
                        p: 0,
                        color: 'inherit',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        disableEditing();
                      }}
                    >
                      <IconBoldCloseCircle
                        size={componentsProps?.icon?.size ?? 12}
                        style={{
                          color: 'inherit',
                          opacity: 0.6,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </ClickAwayListener>
            )
          }
          clickable
          onClick={(e) => {
            e.stopPropagation();
            enableEditing();
          }}
          // Hide the delete icon when editing
          onDelete={
            isEditing
              ? undefined
              : (e) => {
                  e.stopPropagation();
                  onDelete?.(e);
                }
          }
          deleteIcon={
            <IconBoldCloseCircle
              size={componentsProps?.icon?.size ?? 12}
              style={{
                color: 'inherit',
                opacity: 0.6,
              }}
            />
          }
        />
      </Tooltip>
    );
  },
);
