import { Box, TextField, Typography } from '@mui/material';
import { MultipleTagInput } from 'components/common/MultipleTagInput';
import { typography } from 'components/common/Typography/styles';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { SocialHandleInput } from 'features/socialListeningSettings';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening';
import { SocialMediaListeningOnboardingCountrySelectionInput } from 'features/socialMediaListening/views/onboarding/sections/countrySelection';
import {
  ListeningTopicType,
  Platform,
  PlotFeature,
  useCreateCreatorForSocialMediaListeningSettingsGeneralMutation,
  useRemoveCreatorForSocialMediaListeningSettingsGeneralMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { NotificationsSection } from '../notifications';

type Props = {
  currentBrandId: string;
  readOnly?: boolean;
};

export const SocialListeningSettingsGeneralView = ({
  currentBrandId,
  readOnly,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlagContext();

  const [createCreator] =
    useCreateCreatorForSocialMediaListeningSettingsGeneralMutation();
  const [removeCreator] =
    useRemoveCreatorForSocialMediaListeningSettingsGeneralMutation();

  const {
    brand,
    refetchBrand,
    onboardingActions: {
      handleBrandNameUpdate,
      handleBrandKnownNamesUpdate,
      handleBrandRegionsUpdate,
    },
  } = useBrandDataForSocialListeningOnboarding({
    brandId: currentBrandId,
  });

  const topics = brand?.topics.length
    ? [
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.BrandMention,
        ),
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.TopicEngagement,
        ),
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.CreatorTracking,
        ),
      ]
    : [];

  const tiktokCreator = brand?.creators.find(
    (c) => c.platform === Platform.Tiktok,
  );
  const instagramCreator = brand?.creators.find(
    (c) => c.platform === Platform.Instagram,
  );

  if (!brand) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={6} width="100%">
      <Typography variant="headline-lg">General Settings</Typography>
      <Box display="flex" flexDirection="column" mt={2} gap={3}>
        <Typography variant="headline-sm">Brand Name</Typography>
        <TextField
          key={brand.id}
          disabled={readOnly}
          defaultValue={brand.name}
          onBlur={(e) => {
            handleBrandNameUpdate(e.target.value);
          }}
          sx={{
            width: 280,
            '& .MuiOutlinedInput-root': {
              fontWeight: 600,
              color: theme.colors?.primary.black,
              pb: 1,
              paddingRight: `${theme.spacing(2)}`,
              borderRadius: theme.spacing(2),
              backgroundColor: theme.colors?.utility[250],
              border: `2px solid ${theme.colors?.utility[300]}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
      </Box>

      {brand && (
        <>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-sm">
              Alternate names or aliases
            </Typography>
            <MultipleTagInput
              addMode="button"
              value={brand.brandKnownNames}
              onChange={(aliases) => {
                handleBrandKnownNamesUpdate(aliases);
              }}
              sx={{
                px: 4,
                py: 3,
                bgcolor: theme.colors?.utility[250],
                border: `2px solid ${theme.colors?.utility[300]}`,
                borderRadius: 3,
              }}
              componentsProps={{
                chip: {
                  variant: 'filled-borderless',
                  sx: {
                    borderRadius: 2,
                    color: theme.colors?.primary.black,
                    bgcolor: `${theme.colors?.utility[300]} !important`,
                    '&:hover': {
                      bgcolor: `${theme.colors?.utility[400]} !important`,
                    },
                    span: {
                      ...typography['subhead-lg-md'],
                      color: theme.colors?.primary.black,
                    },
                  },
                  componentsProps: {
                    input: {
                      placeholder: 'enter brand alternate name',
                      minWidth: 180,
                    },
                    icon: {
                      size: 16,
                    },
                  },
                },
                input: {
                  autoFocus: true,
                  placeholder:
                    brand.brandKnownNames.length === 0
                      ? 'enter an alternative name or aliases'
                      : 'add more',
                  sx: {
                    ...typography['subhead-lg'],
                    color: theme.colors?.primary.black,
                  },
                },
              }}
            />
          </Box>
          <SocialHandleInput
            readOnly={readOnly}
            creator={tiktokCreator}
            platform={Platform.Tiktok}
            actions={{
              onConfirm: async (creator) => {
                await createCreator({
                  variables: {
                    data: {
                      data: {
                        handle: creator.handle,
                        brandId: brand.id,
                        platform: Platform.Tiktok,
                      },
                    },
                  },
                  update: () => {
                    refetchBrand?.();
                  },
                });
              },
              onRemove: async () => {
                removeCreator({
                  variables: {
                    data: {
                      creatorId: tiktokCreator?.id!,
                      brandId: brand.id,
                    },
                  },
                  update: (cache) => {
                    evictObject(cache, tiktokCreator?.id!, 'CreatorModel');
                  },
                });
              },
            }}
            canRemove={!!tiktokCreator && !!instagramCreator}
          />
          <SocialHandleInput
            readOnly={readOnly}
            creator={instagramCreator}
            platform={Platform.Instagram}
            actions={{
              onConfirm: async (creator) => {
                await createCreator({
                  variables: {
                    data: {
                      data: {
                        handle: creator.handle,
                        brandId: brand.id,
                        platform: Platform.Instagram,
                      },
                    },
                  },
                  update: () => {
                    refetchBrand?.();
                  },
                });
              },
              onRemove: async () => {
                removeCreator({
                  variables: {
                    data: {
                      creatorId: instagramCreator?.id!,
                      brandId: brand.id,
                    },
                  },
                  update: (cache) => {
                    evictObject(cache, instagramCreator?.id!, 'CreatorModel');
                  },
                });
              },
            }}
            canRemove={!!tiktokCreator && !!instagramCreator}
          />
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mt: 2,
        }}
      >
        <Typography variant="headline-sm">Region</Typography>
        <Typography variant="body-xl" color={theme.colors?.utility[800]}>
          TikTok videos: Select country for local content, or leave blank for
          global.
        </Typography>
        <SocialMediaListeningOnboardingCountrySelectionInput
          readOnly={readOnly}
          selectedCountries={brand.regions}
          onCountriesUpdated={(list) => {
            const newCountries = list.map((country) => country.code);
            const existingCountries = brand.regions;
            if (
              JSON.stringify(newCountries) !== JSON.stringify(existingCountries)
            ) {
              handleBrandRegionsUpdate(newCountries);
            }
          }}
          sx={{
            '.country-input .MuiOutlinedInput-root': {
              p: `0 !important`,
              m: `0 !important`,
              color: theme.colors?.primary.black,
              borderRadius: 2,
              backgroundColor: theme.colors?.utility[250],
              border: `2px solid ${theme.colors?.utility[300]}`,
            },
            '.country-input .MuiOutlinedInput-root input': {
              color: theme.colors?.primary.black,
              p: `${theme.spacing(2)} !important`,
              m: `${theme.spacing(2)} !important`,
              fontSize: theme.spacing(3.5),
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.country-tag': {
              color: theme.colors?.primary.black,
              my: 2,
            },
            '.country-tag svg': {
              color: theme.colors?.utility[800],
            },
          }}
          componentsProps={{
            chip: {
              sx: {
                borderRadius: 2,
                bgcolor: theme.colors?.utility[300],
                mr: 0,
                ml: 2,
              },
            },
          }}
        />
      </Box>

      {isFeatureEnabled(PlotFeature.SocialListeningNotifications) && (
        <NotificationsSection topics={topics} currentBrandId={currentBrandId} />
      )}
    </Box>
  );
};
