import { IconButton, Menu, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import {
  BrandSocialPostEngagementStatus,
  ExtendedSocialPostTypeForFilter,
  FollowersType,
  Platform,
  Sentiment,
} from 'graphql/generated';
import moment from 'moment';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { FilterByFollowers } from '../filterSelection/FilterBy/followers';
import { FilterByMinimumPlayCount } from '../filterSelection/FilterBy/minimumPlayCount';
import {
  FilterByOrganicPaid,
  OrganicPaidOptions,
} from '../filterSelection/FilterBy/organicPaid';
import {
  CUSTOM_RANGE_LABEL,
  FilterByPeriod,
} from '../filterSelection/FilterBy/period';
import { FilterByPlatforms } from '../filterSelection/FilterBy/platforms';
import { FilterBySentiments } from '../filterSelection/FilterBy/sentiments';
import { FilterByStatuses } from '../filterSelection/FilterBy/statuses';
import { FilterByTopicRelevancy } from '../filterSelection/FilterBy/topicRelevancy';
import { FilterOption } from '../filterSelection/types';

const SocialListeningFilterOptionsLabel = {
  [SocialListeningFiltersKey.Platforms]: {
    [Platform.Tiktok]: 'Tiktok',
    [Platform.Instagram]: 'Instagram',
  },
  [SocialListeningFiltersKey.Followers]: {
    [FollowersType.Nano]: 'Nano Influencers',
    [FollowersType.Micro]: 'Micro Influencers',
    [FollowersType.Macro]: 'Macro Influencers',
    [FollowersType.Mega]: 'Mega Influencers',
  },
  [SocialListeningFiltersKey.Sentiments]: {
    [Sentiment.Positive]: 'Positive',
    [Sentiment.Neutral]: 'Neutral',
    [Sentiment.Negative]: 'Negative',
  },
  [SocialListeningFiltersKey.Statuses]: {
    [BrandSocialPostEngagementStatus.NotStarted]: 'Not Started',
    [BrandSocialPostEngagementStatus.ForResearch]: 'For Research',
    [BrandSocialPostEngagementStatus.Pass]: 'Pass',
    [BrandSocialPostEngagementStatus.Responded]: 'Responded',
  },
};

interface SocialMediaListeningSelectedFilterItemProps {
  filterOption: FilterOption<any>;
  selectedFilters: {
    [key in SocialListeningFiltersKey]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number
      | ExtendedSocialPostTypeForFilter[];
  };
  onFilterUpdated: (filters: {
    [key in SocialListeningFiltersKey]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number
      | ExtendedSocialPostTypeForFilter[];
  }) => void;
}

export const SocialMediaListeningSelectedFilterItem = ({
  filterOption,
  selectedFilters,
  onFilterUpdated,
}: SocialMediaListeningSelectedFilterItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const option = filterOption;

  const selectedFiltersLabel = useMemo(() => {
    const labels = SocialListeningFilterOptionsLabel[option.key];
    const selectedFiltersValue = selectedFilters[option.key];
    if (labels && Array.isArray(selectedFiltersValue)) {
      return `${selectedFiltersValue.map((f) => labels[f]).join(', ')} ${
        selectedFiltersValue.length > 1
          ? `(${selectedFiltersValue.length})`
          : ''
      }`;
    }
    if (option.key === SocialListeningFiltersKey.OrganicPaid) {
      return selectedFiltersValue ? 'Organic' : 'Paid';
    }
    if (option.key === SocialListeningFiltersKey.MinimumPlayCount) {
      return formatBigNumber(selectedFiltersValue as number);
    }
    if (option.key === SocialListeningFiltersKey.TopicRelevancy) {
      return `${(selectedFiltersValue as number) * 100}%`;
    }
    if (
      option.key === SocialListeningFiltersKey.Period &&
      Array.isArray(selectedFiltersValue) &&
      selectedFiltersValue.length === 3
    ) {
      return selectedFiltersValue[0] === CUSTOM_RANGE_LABEL
        ? `${moment(selectedFiltersValue[1]).format('DD MMM YYYY')} - ${moment(
            selectedFiltersValue[2],
          ).format('DD MMM YYYY')}`
        : `${selectedFiltersValue[0]}`;
    }
    return option.key;
  }, [option.key, selectedFilters]);

  const getOptionComponent = useCallback(() => {
    switch (option.key) {
      case SocialListeningFiltersKey.Platforms: {
        return (
          <FilterByPlatforms
            onChange={({ selectedPlatforms, selectedTypes }) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.Platforms]: selectedPlatforms,
                [SocialListeningFiltersKey.Types]: selectedTypes,
              });
            }}
            selectedPlatforms={
              selectedFilters[
                SocialListeningFiltersKey.Platforms
              ] as unknown as Platform[]
            }
            selectedTypes={
              selectedFilters[
                SocialListeningFiltersKey.Types
              ] as unknown as ExtendedSocialPostTypeForFilter[]
            }
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.Followers: {
        return (
          <FilterByFollowers
            onToggle={(selectedFollowers: FollowersType[]) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.Followers]: selectedFollowers,
              });
            }}
            options={option.options as unknown as FollowersType[]}
            selectedOptions={
              selectedFilters[
                SocialListeningFiltersKey.Followers
              ] as unknown as FollowersType[]
            }
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.Sentiments: {
        return (
          <FilterBySentiments
            onToggle={(selectedSentiments: Sentiment[]) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.Sentiments]: selectedSentiments,
              });
            }}
            options={option.options as unknown as Sentiment[]}
            selectedOptions={
              selectedFilters[
                SocialListeningFiltersKey.Sentiments
              ] as unknown as Sentiment[]
            }
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.Statuses: {
        const selectedOptions = selectedFilters[
          SocialListeningFiltersKey.Statuses
        ] as unknown as BrandSocialPostEngagementStatus[];
        const options =
          option.options as unknown as BrandSocialPostEngagementStatus[];

        return (
          <FilterByStatuses
            onToggle={(selectedStatuses: BrandSocialPostEngagementStatus[]) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.Statuses]: selectedStatuses,
              });
            }}
            options={options}
            selectedOptions={selectedOptions}
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.Period: {
        const selectedPeriod =
          selectedFilters[SocialListeningFiltersKey.Period];

        return (
          <FilterByPeriod
            selectedPeriod={{
              label: selectedPeriod?.[0],
              startDate: selectedPeriod?.[1],
              endDate: selectedPeriod?.[2],
            }}
            onToggle={(selectedPeriod) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.Period]: selectedPeriod
                  ? [selectedPeriod.label, ...selectedPeriod.range]
                  : [],
              });
            }}
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.TopicRelevancy: {
        return (
          <FilterByTopicRelevancy
            selectedTopicRelevancy={
              (selectedFilters[
                SocialListeningFiltersKey.TopicRelevancy
              ] as any) || 0.5
            }
            onToggle={(value) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.TopicRelevancy]: value,
              });
            }}
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.OrganicPaid: {
        return (
          <FilterByOrganicPaid
            options={option.options as unknown as OrganicPaidOptions[]}
            isOrganic={
              selectedFilters[SocialListeningFiltersKey.OrganicPaid] as
                | boolean
                | undefined
            }
            onToggle={(isOrganic) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.OrganicPaid]: isOrganic,
              });
            }}
            type="normal"
          />
        );
      }
      case SocialListeningFiltersKey.MinimumPlayCount: {
        return (
          <FilterByMinimumPlayCount
            selectedMinimumPlayCount={
              selectedFilters[
                SocialListeningFiltersKey.MinimumPlayCount
              ] as unknown as number
            }
            onToggle={(value) => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.MinimumPlayCount]: value,
              });
            }}
            type="normal"
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [onFilterUpdated, option.key, option.options, selectedFilters]);

  const onRemoveFilter = useCallback(() => {
    if (
      [
        SocialListeningFiltersKey.Platforms,
        SocialListeningFiltersKey.Statuses,
        SocialListeningFiltersKey.Followers,
        SocialListeningFiltersKey.Sentiments,
        SocialListeningFiltersKey.Period,
      ].includes(option.key)
    ) {
      onFilterUpdated({
        ...selectedFilters,
        [option.key]: [],
      });
    } else if (option.key === SocialListeningFiltersKey.OrganicPaid) {
      onFilterUpdated({
        ...selectedFilters,
        [option.key]: undefined,
      });
    } else if (option.key === SocialListeningFiltersKey.MinimumPlayCount) {
      onFilterUpdated({
        ...selectedFilters,
        [option.key]: 0,
      });
    } else if (option.key === SocialListeningFiltersKey.TopicRelevancy) {
      onFilterUpdated({
        ...selectedFilters,
        [option.key]: 0.5,
      });
    }
  }, [onFilterUpdated, option.key, selectedFilters]);

  const Icon = socialListeningFilterIconMap[option.key];

  return (
    <>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'center',
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(8),
          p: theme.spacing(1, 2),
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Icon size={16} color={theme.colors?.utility[900]} />
        <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
          {selectedFiltersLabel}
        </Typography>
        <IconButton
          sx={{
            p: 0,
            color: theme.colors?.utility[600],
          }}
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            onRemoveFilter();
          }}
        >
          <IconBoldCloseCircle size={16} color={theme.colors?.utility[600]} />
        </IconButton>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            border: 'none',
          },
        }}
      >
        {getOptionComponent()}
      </Menu>
    </>
  );
};
