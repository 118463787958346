import { BillingPlanName, Platform, SortOrder } from 'graphql/generated';
import queryStr from 'query-string';

export class PlotRoutes {
  static home() {
    return '/';
  }

  static auth() {
    return '/auth/workos';
  }
  static forgotPassword() {
    return '/auth/forgot';
  }

  // org
  static org() {
    return '/org';
  }

  static settings() {
    return `${this.org()}/settings`;
  }

  static account() {
    return `${this.settings()}/account`;
  }

  static members() {
    return `${this.settings()}/members`;
  }

  static billing(action?: { editCard: boolean }) {
    return `${this.settings()}/billing${
      action?.editCard ? '?action=edit-card' : ''
    }`;
  }

  static billingCheckout(query?: { planName?: BillingPlanName }) {
    const createBase = `${this.billing()}/checkout`;

    if (query) {
      return `${createBase}?${queryStr.stringify(query)}`;
    }

    return createBase;
  }

  static socials() {
    return `${this.settings()}/socials`;
  }

  static socialListeningRequestAccessLink(brandId: string) {
    return `/request-brand-access/${brandId}`;
  }

  static socialListeningSettings(queryParams?: {
    tab?: string;
    topicId?: string;
    brandId?: string;
  }) {
    if (queryParams) {
      return `${this.settings()}/social-listening?${queryStr.stringify(
        queryParams,
      )}`;
    }
    return `${this.settings()}/social-listening`;
  }

  // task routes
  static task(taskId: string) {
    return `/tasks/${taskId}`;
  }

  static tasks() {
    return `/tasks`;
  }
  static tasksRequestAccess() {
    return `/tasks/request-access`;
  }

  // onboarding routes
  static onboarding() {
    return '/onboarding';
  }

  static verify() {
    return '/verify';
  }

  static onboardingJuicebox() {
    return '/onboarding/creative-juicebox';
  }

  static registerText() {
    return '/register-text';
  }

  // juicebox routes
  static juicebox() {
    return '/juicebox';
  }

  static juiceboxRequestAccess() {
    return `${PlotRoutes.juicebox()}/request-access`;
  }

  static juiceboxTrending() {
    return '/juicebox/trending';
  }

  static juiceboxTrendingRequestAccess() {
    return '/juicebox/trending/request-access';
  }

  static juice(id: string) {
    return `/juicebox/${id}`;
  }

  static trendingJuice(id: string) {
    return `/juicebox/trending/${id}`;
  }

  static juiceboxNote({ id, title }: { id: string; title?: string | null }) {
    if (title) {
      // replace all special character in title
      const newTitle = title.replace(/[^a-zA-Z0-9 -]/g, '');
      return `/juicebox/note/${newTitle.replaceAll(' ', '-')}-${id}`;
    }

    return `/juicebox/note/${id}`;
  }

  static trendingJuiceboxNote(id?: string) {
    return `/juicebox/trending/note/${id}`;
  }

  static juicePreview(id: string) {
    return `/juicebox/${id}/preview`;
  }

  // collection routes
  static collection(id?: string) {
    return `/collections/${id}`;
  }

  // inbox
  static inbox() {
    return `/inbox`;
  }

  static notifications() {
    return `/notifications`;
  }
  static aiOrganize() {
    return `/ai-organize`;
  }

  static socialListening() {
    return `/social-listening`;
  }

  static socialListeningForBrand(brandId: string) {
    return `/social-listening/brands/${brandId}`;
  }
  static socialListeningBrandOnboarding(brandId?: string) {
    if (brandId) {
      return `/social-listening/onboarding/brand/${brandId}`;
    }
    return `/social-listening/onboarding/brand`;
  }
  static socialListeningOnboarding(
    type: 'topic' | 'creator' | 'competitors',
    brandId: string,
    options?: {
      new: boolean;
    },
  ) {
    if (brandId) {
      if (options?.new) {
        return `/social-listening/brands/${brandId}/onboarding/${type}?new=true`;
      }
      return `/social-listening/brands/${brandId}/onboarding/${type}`;
    }

    if (options?.new) {
      return `/social-listening/onboarding/${type}?new=true`;
    }
    return `/social-listening/onboarding/${type}`;
  }
  static socialListeningAnalyticsInbound(brandId: string) {
    return `/social-listening/brands/${brandId}/analytics/inbound`;
  }
  static socialListeningAnalyticsOutbound(brandId: string) {
    return `/social-listening/brands/${brandId}/analytics/outbound`;
  }
  static socialListeningAnalyticsOutboundInteractions(
    brandId: string,
    query?: {
      responderIds?: string[];
      platforms?: Platform[] | null;
      startDate?: Date;
      endDate?: Date;
      sortBy?: string;
      sortOrder?: SortOrder;
    },
  ) {
    if (query) {
      return `${this.socialListeningAnalyticsOutbound(
        brandId,
      )}/interactions?${queryStr.stringify(query)}`;
    }
    return `${this.socialListeningAnalyticsOutbound(brandId)}/interactions`;
  }
  static socialListeningAnalyticsOutboundLeaderboardDetail(
    leaderboardId: string,
    brandId: string,
  ) {
    return `${this.socialListeningAnalyticsOutbound(
      brandId,
    )}/leaderboard/${leaderboardId}`;
  }
  static socialListeningAnalyticsCompetitors(brandId: string) {
    return `/social-listening/brands/${brandId}/analytics/competitors`;
  }
  static socialListeningPost(
    postId: string,
    brandId: string,
    query?: { tab?: 'creator' | 'comments' | 'transcript' },
  ) {
    const baseStr = brandId
      ? `/social-listening/brands/${brandId}/posts`
      : '/social-listening/posts';

    if (query) {
      return `${baseStr}/${postId}?${queryStr.stringify(query)}`;
    }
    return `${baseStr}/${postId}`;
  }
  static socialListeningCompetitor(
    competitorId: string,
    brandId: string,
    platform?: Platform,
    query?: { creatorId?: string; hashtag?: string },
  ) {
    if (platform) {
      return `${this.socialListeningCompetitors(brandId)}/${competitorId}/${
        platform === Platform.Instagram ? 'instagram' : 'tiktok'
      }${query ? `?${queryStr.stringify(query)}` : ''}`;
    }
    return `${this.socialListeningCompetitors(brandId)}/${competitorId}${
      query ? `?${queryStr.stringify(query)}` : ''
    }`;
  }
  static socialListeningCompetitors(brandId: string) {
    return `/social-listening/brands/${brandId}/competitors`;
  }
  static socialListeningIntro() {
    return `/social-listening/intro`;
  }

  // content calendar
  static contentCalendar() {
    return `/content-calendar`;
  }

  static contentCalendarRequestAccess() {
    return `/content-calendar/request-access`;
  }

  static contentIdea(id: string) {
    return `/content-ideas/${id}`;
  }

  // trending audio group
  static trendingAudio() {
    return `/juicebox/trending/audio`;
  }
  static trendingAudioDetail(id: string) {
    return `/juicebox/trending/audio/${id}`;
  }

  // user access request
  static accessRequest() {
    return '/access-request';
  }

  static attachment(id: string) {
    return `/attachments/${id}`;
  }
}
