import { useDisclosure } from '@dwarvesf/react-hooks';
import { Popover, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldMicrophone } from 'components/icons/components/bold/IconBoldMicrophone';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { useUserContext } from 'contexts/users/User.context';
import { useRef } from 'react';
import { theme } from 'styles/theme';
import { StyledIconButton, tooltipStyle } from './styles';

export type AddPostsForOutboundCommentTrackingButtonProps = {
  brandIds: string[];
  onChange: (brandIds: string[]) => void;
};

export const AddPostsForOutboundCommentTrackingButton = (
  props: AddPostsForOutboundCommentTrackingButtonProps,
) => {
  const { brandIds, onChange } = props;

  const { user } = useUserContext();
  const brands = user?.organization.socialListeningBrands || [];

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  return (
    <>
      <StyledIconButton
        ref={anchorElRef}
        tooltip="Add comment to outbound dashboard"
        tooltipPopperProps={{
          sx: tooltipStyle,
        }}
        sx={{
          ...(brandIds.length > 0
            ? {
                bgcolor: `${theme.colors?.utility['orange-3']} !important`,
              }
            : {}),
        }}
        placement="top"
        onClick={openPopover}
      >
        <IconBoldMicrophone />
      </StyledIconButton>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorElRef.current}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            borderRadius: 3,
            bgcolor: '#FFFFFFCC',
            backdropFilter: 'blur(40px)',
            p: 3,
            width: 300,
            maxHeight: 300,
          },
        }}
      >
        <Typography
          variant="headline-xxs"
          sx={{
            color: theme.colors?.utility[700],
            display: 'block',
            px: 3,
            mb: 2,
          }}
        >
          Social Listening
        </Typography>
        {brands.map((brand) => {
          return (
            <CheckboxMenuItem
              key={brand.id}
              value={brand.id}
              label={brand.name}
              checked={brandIds.includes(brand.id)}
              checkboxPosition="end"
              renderCheckbox={(checked) => {
                if (checked) {
                  return <IconBoldAddCircle size={16} />;
                }

                return <IconLinearAddCircle size={16} />;
              }}
              onClick={() => {
                onChange(
                  brandIds.includes(brand.id)
                    ? brandIds.filter((id) => id !== brand.id)
                    : [...brandIds, brand.id],
                );
              }}
            />
          );
        })}
      </Popover>
    </>
  );
};
