import { gql } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ManuallyParsedSocialPostTrackerDialogView } from 'features/manuallyParsedSocialPostTracker';
import {
  SocialPostCommentCardView,
  SocialPostCommentCardViewSkeleton,
} from 'features/socialPostComment';
import {
  Platform,
  SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc,
  SocialPostCommentsFilters,
  SocialPostCommentSource,
  useGetSocialPostCommentsForSocialMediaListeningMostEngagedResponsesQuery,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import moment from 'moment/moment';
import { SocialMediaListeningAnalyticsOutboundInteractionFilters } from 'pages/socialMediaListening/analytics/outbound/interaction/components';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetSocialPostCommentsForSocialMediaListeningMostEngagedResponses(
    $brandId: String!
    $data: SocialPostCommentsInput!
  ) {
    socialPostComments(data: $data) {
      data {
        ...SocialPostCommentFragmentSocialPostCommentCardView
      }
      meta {
        totalCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc}
`;

const TAKE = 6;

type SocialMediaListeningMostEngagedResponsesProps = {
  brandId: string;
};

export const SocialMediaListeningMostEngagedResponses = (
  props: SocialMediaListeningMostEngagedResponsesProps,
) => {
  const { brandId } = props;

  const navigate = useGuardNavigate();
  const location = useLocation();
  const { backgroundLocation } =
    (location.state as {
      backgroundLocation?: Location;
    }) || {};

  const [filters, setFilters] = useState<SocialPostCommentsFilters>({
    brandIds: [brandId],
    platforms: Object.values(Platform),
    sources: Object.values(SocialPostCommentSource),
    dateRange: {
      startDate: moment().subtract(30, 'days').toDate(),
      endDate: moment().toDate(),
    },
    topicIds: [],
  });

  const { data, loading } =
    useGetSocialPostCommentsForSocialMediaListeningMostEngagedResponsesQuery({
      variables: {
        brandId,
        data: {
          filters: {
            ...filters,
          },
          take: TAKE,
        },
      },
    });
  const socialPostComments = data?.socialPostComments.data || [];
  const isEmpty = !loading && !socialPostComments.length;

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(6) }}
    >
      <Box
        sx={{
          bgcolor: theme.colors?.primary.white,
          borderRadius: theme.spacing(6),
          border: `1px solid ${theme.colors?.utility[300]}`,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
          p: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="headline-lg">
              Most engaged responses
            </Typography>
            <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
              This displays the most engaged responses in your community.
            </Typography>
          </Box>

          <Box>
            <SocialMediaListeningAnalyticsOutboundInteractionFilters
              filters={filters}
              setFilters={setFilters}
            />
          </Box>
        </Box>

        <Box sx={{ margin: theme.spacing(9, 0) }}>
          {loading ? (
            <SocialPostCommentCardViewSkeleton numberOfCards={3} />
          ) : isEmpty ? (
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} md={4} xl={3}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: theme.spacing(37),
                      height: theme.spacing(49),
                      borderRadius: theme.spacing(4),
                      bgcolor: theme.colors?.utility[275],
                    }}
                  />
                  <Box
                    sx={{
                      bgcolor: theme.colors?.utility[250],
                      borderRadius: 4,
                      padding: 3,
                      zIndex: 1,
                      ml: -7,
                    }}
                  >
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.utility[700]}
                    >
                      No comments yet
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <>
              {socialPostComments.length === 0 && (
                <Typography
                  variant="subhead-xl"
                  color={theme.colors?.utility[600]}
                  textAlign="center"
                  display="block"
                >
                  None found
                </Typography>
              )}

              <Grid container spacing={8}>
                {socialPostComments.map((socialCommentPost) => {
                  return (
                    <Grid
                      item
                      key={socialCommentPost.id}
                      xs={12}
                      sm={6}
                      md={4}
                      xl={3}
                    >
                      <SocialPostCommentCardView
                        socialPostComment={socialCommentPost}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <ManuallyParsedSocialPostTrackerDialogView brandId={brandId} />
          </Box>

          {socialPostComments.length > 0 && (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                sx={{
                  textDecoration: 'underline',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => {
                  navigate(
                    PlotRoutes.socialListeningAnalyticsOutboundInteractions(
                      brandId,
                      {
                        platforms: filters.platforms,
                        startDate: filters.dateRange?.startDate,
                        endDate: filters.dateRange?.endDate,
                      },
                    ),
                    {
                      state: {
                        backgroundLocation: backgroundLocation || location,
                      },
                    },
                  );
                }}
              >
                See all
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subhead-xl" color={theme.colors?.utility[600]}>
          Note: Only comments ranking in the top 100 by engagement are
          processed. Processing takes up to 24 hours
        </Typography>
        <Typography variant="subhead-xl" color={theme.colors?.utility[600]}>
          Last updated yesterday at 12pm
        </Typography>
      </Box>
    </Box>
  );
};
