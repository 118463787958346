import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid } from '@mui/material';
import {
  BrandCompetitorInfo,
  BrandCompetitorInfoSkeleton,
  BrandCompetitorsPlatformSelector,
} from 'features/brandCompetitors';
import { CompetitorTopCreators } from 'features/brandCompetitors/components/competitorTopCreators';
import {
  Platform,
  useGetCompetitorForBrandCompetitorDetailQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { BrandMentionsChart } from '../../components/brandMentionsChart';
import { CompetitorCommentInsights } from '../../components/commentInsights';
import { CompetitorSentiment } from '../../components/competitorSentiment';
import { CompetitorSummary } from '../../components/competitorSummary/CompetitorSummary';
import { CompetitorTopHashtags } from '../../components/competitorTopHashtags';
import { CompetitorTopPosts } from '../../components/competitorTopPosts';
import { BrandCreatorPostsView } from '../brandCreatorPosts/BrandCreatorPostsView';
import { BrandHashtagPostsView } from '../brandHashtagPosts/BrandHashtagPosts';

type Props = {
  currentBrandId: string;
  competitorId: string;
  platform: Platform;
  searchParams?: URLSearchParams;
};

export const BrandCompetitorDetailView = ({
  currentBrandId,
  competitorId,
  platform,
  searchParams,
}: Props) => {
  const {
    isOpen: isCreatorPostsOpen,
    onOpen: onCreatorPostsOpen,
    onClose: onCreatorPostsClose,
  } = useDisclosure();
  const {
    isOpen: isHashtagPostsOpen,
    onOpen: onHashtagPostsOpen,
    onClose: onHashtagPostsClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const [currentPlatform, setCurrentPlatform] = useState(platform);
  const [selectedCreatorId, setSelectedCreatorId] = useState('');
  const [selectedHashtag, setSelectedHashtag] = useState('');

  const [params] = useSearchParams();
  const creatorId = params.get('creatorId');
  const hashtag = params.get('hashtag');

  useEffect(() => {
    if (creatorId) {
      setSelectedCreatorId(creatorId);
      onCreatorPostsOpen();
    }
  }, [creatorId]);

  useEffect(() => {
    if (hashtag) {
      setSelectedHashtag(hashtag);
      onHashtagPostsOpen();
    }
  }, [hashtag]);

  const { data: competitorData, loading: loadingCompetitorData } =
    useGetCompetitorForBrandCompetitorDetailQuery({
      variables: {
        competitorId,
      },
    });

  const availablePlatforms =
    competitorData?.competitor.trackingBrand?.creators?.map(
      (creator) => creator.platform,
    );

  const currentCreatorId =
    competitorData?.competitor.trackingBrand?.creators.find(
      (creator) => creator.platform === currentPlatform,
    )?.id || '';

  useEffect(() => {
    navigate(
      PlotRoutes.socialListeningCompetitor(
        competitorId,
        currentBrandId,
        currentPlatform,
        {
          creatorId: creatorId || undefined,
          hashtag: selectedHashtag || undefined,
        },
      ),
      {
        replace: true,
      },
    );
  }, [currentPlatform]);

  return (
    <Box
      mt={16}
      p={8}
      width="100%"
      display="flex"
      flexDirection="column"
      gap={6}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        {loadingCompetitorData || !competitorData ? (
          <BrandCompetitorInfoSkeleton />
        ) : (
          <BrandCompetitorInfo
            competitor={competitorData.competitor}
            currentPlatform={currentPlatform}
          />
        )}
        <Box>
          {availablePlatforms && availablePlatforms?.length > 1 && (
            <BrandCompetitorsPlatformSelector
              defaultPlatform={currentPlatform}
              onPlatformSelect={setCurrentPlatform}
            />
          )}
        </Box>
      </Box>

      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} lg={4}>
          <Box display="flex" flexDirection="column" gap={6} width="100%">
            <CompetitorSummary
              brandId={currentBrandId}
              competitorId={competitorId}
              platform={currentPlatform as Platform}
            />
            <CompetitorTopHashtags
              competitorId={competitorId}
              creatorId={currentCreatorId}
              currentPlatform={currentPlatform}
              onHashtagClick={(hashtag) => {
                setSelectedHashtag(hashtag);
                onHashtagPostsOpen();
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <CompetitorTopPosts
            brandId={currentBrandId}
            competitorId={competitorId}
            creatorId={currentCreatorId}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={12}>
          <CompetitorTopCreators
            competitorId={competitorId}
            creatorId={currentCreatorId}
            brandId={currentBrandId}
            onCreatorIdSelect={(creatorId) => {
              setSelectedCreatorId(creatorId);
              onCreatorPostsOpen();
            }}
          />
        </Grid>
      </Grid>

      <BrandMentionsChart
        competitorId={competitorId}
        currentPlatform={currentPlatform}
      />

      <CompetitorSentiment
        competitorId={competitorId}
        currentBrandId={currentBrandId}
        trackingBrandId={competitorData?.competitor.trackingBrand?.id || ''}
        currentPlatform={currentPlatform}
      />

      <CompetitorCommentInsights
        currentBrandId={currentBrandId}
        competitorId={competitorId}
        trackingBrandId={competitorData?.competitor.trackingBrand?.id || ''}
        platform={currentPlatform}
      />
      {competitorData?.competitor.trackingBrand && selectedCreatorId && (
        <BrandCreatorPostsView
          competitorId={competitorId}
          competitorBrand={competitorData?.competitor.trackingBrand}
          currentBrandId={currentBrandId}
          creatorId={selectedCreatorId}
          isOpen={isCreatorPostsOpen}
          onClose={() => {
            setSelectedCreatorId('');
            onCreatorPostsClose();
          }}
        />
      )}
      {competitorData?.competitor.trackingBrand && (
        <BrandHashtagPostsView
          competitorId={competitorId}
          competitorBrand={competitorData?.competitor.trackingBrand}
          brandCreatorId={currentCreatorId}
          hashtag={selectedHashtag}
          platform={currentPlatform}
          isOpen={isHashtagPostsOpen}
          onClose={() => {
            onHashtagPostsClose();
          }}
        />
      )}
    </Box>
  );
};
