import { gql } from '@apollo/client';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { Tooltip } from 'components/common/Tooltip';
import { IconLinearMore2 } from 'components/icons/components/linear/IconLinearMore2';
import { useUserContext } from 'contexts/users/User.context';
import { OrgRoleSelector } from 'features/organizationMembers/components/OrgRoleSelector';
import {
  InternalOrganizationRole,
  OrganizationPermission,
  PermissionLevel,
  UserProfileFragmentInternalMembersFragment,
  useUpdateBrandPermissionsForInternalMembersMutation,
} from 'graphql/generated';
import { useOrganizationPermissions } from 'hooks/permissions/useOrganizationPermissions';
import moment from 'moment';
import { useMembersHandlers } from 'pages/org/settings/members/hooks/useMembersHandlers';
import { theme } from 'styles/theme/theme';
import { Updater } from 'use-immer';
import { OrganizationMembersContextMenu } from '../../../components';
import { MemberStats } from '../sections/MemberStats';
import { StyledTableBodyCell, StyledTableHeadCell } from '../styles';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandPermissionsForInternalMembers(
    $data: UpdateBrandPermissionsInput!
  ) {
    updateBrandPermissions(data: $data) {
      success
      message
    }
  }
`;

const columns = ['Name', 'Title', 'Date Added', 'Role', ''];

type InternalMembersProps = {
  users: UserProfileFragmentInternalMembersFragment[];
  showCheckBox?: boolean;
  selectionLimit?: number;
  hideTableHeaders?: boolean;
  hideMoreOptionMenu?: boolean;
  selectedMembers?: string[];
  setSelectedMembers?: Updater<string[]>;
};

export const InternalMembers = (props: InternalMembersProps) => {
  const {
    users,
    showCheckBox,
    selectionLimit,
    hideTableHeaders,
    hideMoreOptionMenu,
    selectedMembers,
    setSelectedMembers,
  } = props;

  const [updateBrandPermissions] =
    useUpdateBrandPermissionsForInternalMembersMutation();
  const { user, orgBilling, refetchUserData } = useUserContext();
  const paidMemberLimitReached = orgBilling
    ? orgBilling.organizationMemberUsage >=
      (orgBilling.organizationMemberLimit || 1)
    : false;

  const permissions = useOrganizationPermissions();
  const canManageMembers = permissions.includes(
    OrganizationPermission.MemberManagement,
  );

  const { onUserRoleChange } = useMembersHandlers();

  return (
    <>
      <MemberStats />
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          {!hideTableHeaders && (
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <StyledTableHeadCell key={`${column}-${index}`}>
                    {column}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {[...users]
              .sort((a, b) => {
                if (a.title === '') {
                  return 1;
                }
                if (b.title === '') {
                  return -1;
                }

                return 0;
              })
              .map((u) => {
                const isNotSelectable =
                  selectionLimit !== undefined &&
                  selectedMembers !== undefined &&
                  selectedMembers.length >= selectionLimit &&
                  !selectedMembers.includes(u.id);

                // Check if we should show paywall for role selector for this user
                // Paywall should be shown if:
                // 1. User is active, AND
                // 2. User is a SL user, AND
                // 3. Current org has reached all-feature member limit
                const showPaywall = Boolean(
                  u.hasSignedUp &&
                    !u.isDisabled &&
                    u.hasBeenApprovedByAdmin &&
                    u.role === InternalOrganizationRole.SocialListeningUser &&
                    paidMemberLimitReached,
                );

                return (
                  <TableRow
                    key={u.id}
                    sx={
                      u.isDisabled || isNotSelectable
                        ? { filter: 'grayscale(1)', opacity: 0.6 }
                        : {}
                    }
                  >
                    <StyledTableBodyCell>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: theme.spacing(5),
                          alignItems: 'center',
                        }}
                      >
                        {showCheckBox && (
                          <Tooltip
                            title={
                              user?.id === u.id
                                ? 'You cannot deselect yourself'
                                : selectedMembers?.includes(u.id) &&
                                  selectedMembers?.length === 1
                                ? 'Must have at least 1 member in your company'
                                : ''
                            }
                          >
                            <Checkbox
                              checked={selectedMembers?.includes(u.id)}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                if (
                                  isNotSelectable ||
                                  (!event.target.checked &&
                                    ((selectedMembers &&
                                      selectedMembers.length === 1) ||
                                      user?.id === u.id))
                                ) {
                                  return;
                                }

                                if (selectedMembers) {
                                  if (selectedMembers.includes(u.id)) {
                                    setSelectedMembers?.((draft) => {
                                      return draft.filter((m) => m !== u.id);
                                    });
                                  } else {
                                    setSelectedMembers?.((draft) => {
                                      draft.push(u.id);
                                    });
                                  }
                                }
                              }}
                            />
                          </Tooltip>
                        )}
                        <AvatarWithName user={u} />
                      </Box>
                    </StyledTableBodyCell>

                    <StyledTableBodyCell>
                      <Typography
                        variant="body-md"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        {u.title || '-'}
                      </Typography>
                    </StyledTableBodyCell>

                    <StyledTableBodyCell>
                      <Typography
                        variant="body-md"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        {moment(u.createdAt).format('MMM D, hh:mmA')}
                      </Typography>
                    </StyledTableBodyCell>

                    <StyledTableBodyCell>
                      <OrgRoleSelector
                        showPaywall={showPaywall}
                        isDisabledUser={!!u.isDisabled}
                        initialValue={u.role || InternalOrganizationRole.User}
                        viewOnly={!canManageMembers}
                        onRoleChange={async (role) => {
                          if (role !== u.role) {
                            await onUserRoleChange({ userId: u.id, role });
                          }
                        }}
                        extendedSLMenu={{
                          userInfo: {
                            id: u.id,
                            orgId: u.organization.id,
                          },
                          onSLBrandChange: async (brandId, type) => {
                            const existingBrandInviteMembers =
                              user?.socialListeningBrands.find(
                                (brand) => brand.id === brandId,
                              )?.inviteMembers;

                            if (type === 'add') {
                              const newMembersList = [
                                ...(existingBrandInviteMembers || []).map(
                                  (member) => ({
                                    email: member.user.email,
                                    permission: member.permissionLevel,
                                  }),
                                ),
                              ];
                              if (
                                !newMembersList.find(
                                  (member) => member.email === u.email,
                                )
                              ) {
                                newMembersList.push({
                                  email: u.email,
                                  permission: PermissionLevel.Full,
                                });

                                await updateBrandPermissions({
                                  variables: {
                                    data: {
                                      brandId,
                                      members: newMembersList,
                                    },
                                  },
                                });
                                refetchUserData();
                              }
                            } else if (type === 'remove') {
                              const newMembersList = [
                                ...(existingBrandInviteMembers || []).map(
                                  (member) => ({
                                    email: member.user.email,
                                    permission: member.permissionLevel,
                                  }),
                                ),
                              ];
                              const memberIndex = newMembersList.findIndex(
                                (member) => member.email === u.email,
                              );
                              if (memberIndex !== -1) {
                                newMembersList.splice(memberIndex, 1);

                                await updateBrandPermissions({
                                  variables: {
                                    data: {
                                      brandId,
                                      members: newMembersList,
                                    },
                                  },
                                });
                                refetchUserData();
                              }
                            }
                          },
                        }}
                      />
                    </StyledTableBodyCell>

                    {!hideMoreOptionMenu &&
                      canManageMembers &&
                      // show when there is no member limit or organization can have more than 1 members
                      (!orgBilling?.organizationMemberLimit ||
                        orgBilling?.organizationMemberLimit > 1) && (
                        <StyledTableBodyCell>
                          <OrganizationMembersContextMenu
                            memberId={u.id}
                            isUserDisabled={!!u.isDisabled}
                            renderButton={() => (
                              <IconLinearMore2
                                color={theme.colors?.utility[800]}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          />
                        </StyledTableBodyCell>
                      )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
