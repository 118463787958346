import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Popover, SxProps, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { TooltipForTruncatedText } from 'components/common/TooltipForTruncatedText';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { TopicLabel } from 'features/topic';
import {
  TopicFragmentTopicLabelFragmentDoc,
  useGetBrandsDataForSocialMediaListeningAnalyticsOutboundViewQuery,
} from 'graphql/generated';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

export const TOPIC_FRAGMENT_SOCIAL_MEDIA_LISTENING_FILTER_BY_TOPICS_BUTTON = gql`
  fragment TopicFragmentSocialMediaListeningFilterByTopicsButton on TopicModel {
    id
    name
    ...TopicFragmentTopicLabel
  }
  ${TopicFragmentTopicLabelFragmentDoc}
`;

export type SocialMediaListeningFilterByTopicsButtonProps = {
  selectedTopicIds: string[];
  componentProps?: {
    sx?: SxProps;
  };
  onChange: (topicIds: string[]) => void;
};

export const SocialMediaListeningFilterByTopicsButton = (
  props: SocialMediaListeningFilterByTopicsButtonProps,
) => {
  const { selectedTopicIds, onChange, componentProps } = props;

  // Reusing query as we can benefit from the cache
  const { brandId = '' } = useParams();
  const { data: brandData } =
    useGetBrandsDataForSocialMediaListeningAnalyticsOutboundViewQuery();
  const brand = brandData?.brands.find((b) => b.id === brandId);
  const topics = brand?.topics || [];

  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  const getButtonText = () => {
    const selectedCount = selectedTopicIds.length;
    if (selectedCount === 0) return 'Topics';
    if (selectedCount === 1) return '1 Topic selected';
    return `${selectedCount} Topics selected`;
  };

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        startIcon={<IconLinearClipboardText size={16} />}
        endIcon={<IconLinearArrowDown size={16} />}
        sx={{
          borderRadius: 2,
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-xl">{getButtonText()}</Typography>
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            p: 4,
            width: 270,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {topics.map((topic) => {
          const { id, name } = topic;
          const checked = selectedTopicIds.includes(id);

          return (
            <CheckboxMenuItem
              key={id}
              value={id}
              checked={checked}
              onClick={() => {
                if (!checked) {
                  onChange([...selectedTopicIds, id]);
                } else {
                  onChange(selectedTopicIds.filter((x) => x !== id));
                }
              }}
              label={
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                  }}
                >
                  <TooltipForTruncatedText title={name}>
                    <Typography variant="subhead-lg">{name}</Typography>
                  </TooltipForTruncatedText>
                  <TopicLabel topic={topic} />
                </Box>
              }
            />
          );
        })}
      </Popover>
    </>
  );
};
