import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Checkbox, SxProps, Typography } from '@mui/material';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { Tooltip } from 'components/common/Tooltip';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import { CollectionMenuItemView } from 'features/collection';
import {
  CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
  ContentCalendarCollectionMenuItem,
} from 'features/contentCalendar';
import { JuiceboxFilterButton } from 'features/juicebox/components';
import { JuiceboxFilter, JuiceboxFilterType } from 'features/juicebox/hooks';
import {
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  CollectionFragmentJuiceboxCollectionSelectButtonFilterFragment,
  CollectionFragmentJuiceboxCollectionSelectButtonFilterFragmentDoc,
  PlotFeature,
  useGetCollectionsSmartSearchForJuiceboxCollectionSelectButtonFilterQuery,
} from 'graphql/generated';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  UNORGANIZED_POSTS_MENU_ITEM_VALUE_KEY,
  UnorganizedPostsMenuItem,
} from './UnorganizedPostsMenuItem';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentJuiceboxCollectionSelectButtonFilter on CollectionModel {
    id
    ...CollectionFragmentCollectionMenuItemView
  }
  ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionsSmartSearchForJuiceboxCollectionSelectButtonFilter(
    $filters: CollectionFilters!
    $take: Int
    $sortType: SortType
    $after: String
  ) {
    collectionsSmartSearch(
      filters: $filters
      take: $take
      sortType: $sortType
      after: $after
    ) {
      data {
        item {
          ...CollectionFragmentJuiceboxCollectionSelectButtonFilter
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CollectionFragmentJuiceboxCollectionSelectButtonFilterFragmentDoc}
`;

interface Props {
  organizationId?: string;
  selectedCollections: JuiceboxFilter[];
  setSelectedCollections: Dispatch<SetStateAction<JuiceboxFilter[]>>;
  parentCollectionId?: string;
  filterTitle?: string;
  componentsProps?: {
    filterButton?: {
      sx?: SxProps;
    };
  };
  canFilterByUnorganizedPosts?: boolean;
}

export const JuiceboxCollectionSelectButtonFilter = (props: Props) => {
  const {
    organizationId,
    selectedCollections,
    setSelectedCollections,
    parentCollectionId,
    filterTitle = 'Collections',
    componentsProps = {},
    canFilterByUnorganizedPosts,
  } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();

  const { orgBilling, joinedOrgBillings } = useUserContext();
  const collectionOrgBilling = useMemo(() => {
    if (!organizationId || organizationId === orgBilling?.organizationId) {
      return orgBilling;
    }

    return (
      joinedOrgBillings?.find((j) => j.organizationId === organizationId) ??
      orgBilling
    );
  }, [organizationId, orgBilling, joinedOrgBillings]);

  const shouldDisableCollectionFilterButton =
    collectionOrgBilling?.collectionUsageLimit !== null &&
    collectionOrgBilling?.collectionUsageLimit !== undefined &&
    collectionOrgBilling?.collectionUsageLimit === 0;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [query, setQuery] = useState('');

  const handlePopover = () => {
    setQuery('');
    onOpen();
  };

  const includeContentIdeaCollections = useMemo(() => {
    return parentCollectionId ? parentCollectionId !== '' : query.trim() !== '';
  }, [parentCollectionId, query]);

  const { data, loading, fetchMore } =
    useGetCollectionsSmartSearchForJuiceboxCollectionSelectButtonFilterQuery({
      variables: {
        filters: {
          parentCollectionIds: parentCollectionId
            ? [parentCollectionId]
            : undefined,
          query,
          includeContentIdeaCollections,
        },
        take: 10,
      },
      fetchPolicy: 'cache-and-network',
      skip: !isOpen,
    });

  const fetchMoreCollections = () => {
    if (!data?.collectionsSmartSearch.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        filters: {
          parentCollectionId,
          query,
          includeContentIdeaCollections,
        },
        after: data?.collectionsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          collectionsSmartSearch: {
            ...fetchMoreResult.collectionsSmartSearch,
            data: [
              ...prev.collectionsSmartSearch.data,
              ...fetchMoreResult.collectionsSmartSearch.data.filter(
                (x) =>
                  !prev.collectionsSmartSearch.data.some(
                    (y) => y.item.id === x.item.id,
                  ),
              ),
            ],
          },
        };
      },
    });
  };

  const collections: CollectionFragmentJuiceboxCollectionSelectButtonFilterFragment[] =
    useMemo(() => {
      return data?.collectionsSmartSearch.data.map((d) => d.item) || [];
    }, [data]);

  const options = useMemo(() => {
    if (query) {
      return collections.map((c) => ({
        value: c.id,
        label: c.name,
      }));
    }

    return [
      ...(canFilterByUnorganizedPosts
        ? [
            {
              value: UNORGANIZED_POSTS_MENU_ITEM_VALUE_KEY,
              label: 'Unorganized Posts',
            },
          ]
        : []),
      ...(isFeatureEnabled(PlotFeature.ContentCalendar)
        ? [
            {
              value: CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
              label: 'Content Calendar',
            },
          ]
        : []),
      ...collections.map((c) => ({
        value: c.id,
        label: c.name,
      })),
    ];
  }, [collections, query, canFilterByUnorganizedPosts, isFeatureEnabled]);

  return (
    <AutocompletePopup
      loading={loading}
      options={options}
      disabled={shouldDisableCollectionFilterButton}
      renderTrigger={() => (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: theme.colors?.primary.black,
                color: theme.colors?.primary.parchment,
                padding: theme.spacing(2, 3),
              },
            },
          }}
          title={
            !collectionOrgBilling || !shouldDisableCollectionFilterButton ? (
              ''
            ) : (
              <BillingCollectionLimitIndicator
                organizationBilling={collectionOrgBilling}
                variant="text"
                customText="to enable this filter"
              />
            )
          }
        >
          <Box>
            <JuiceboxFilterButton
              Icon={IconOutlineHashtag}
              disabled={shouldDisableCollectionFilterButton}
              text={filterTitle}
              onClick={handlePopover}
              sx={componentsProps.filterButton?.sx}
            />
          </Box>
        </Tooltip>
      )}
      inputValue={query}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setQuery(value);
        }
      }}
      noOptionsText="No collections found"
      loadMoreItems={fetchMoreCollections}
      multiple
      disableDefaultSearchFilter
      renderOption={(props, option) => {
        const collection = collections.find((c) => c.id === option.value);

        const commonMenuItemProps = {
          selectedCollectionIds: selectedCollections.map((c) => c.value),
          renderSelectIcon: (isSelected) => (
            <Checkbox
              checked={isSelected}
              disableRipple
              sx={{
                padding: 0,
                '& svg': {
                  width: '16px',
                  height: '16px',
                },
              }}
            />
          ),
          onClick: (
            selectedCollection: CollectionFragmentJuiceboxCollectionSelectButtonFilterFragment,
          ) => {
            if (!selectedCollection) {
              return;
            }

            setSelectedCollections((prev) => {
              if (prev.some((c) => c.value === selectedCollection.id)) {
                return prev.filter((c) => c.value !== selectedCollection.id);
              }

              return [
                ...prev,
                {
                  filterType: JuiceboxFilterType.PostCollections,
                  value: selectedCollection.id,
                  label: selectedCollection.name,
                },
              ];
            });
          },
          componentProps: {
            listItem: {
              sx: {
                maxWidth: 240,
              },
            },
            menu: {
              sx: {
                '& .MuiPaper-root': {
                  boxShadow:
                    'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(24px)',
                  width: 310,
                },
              },
            },
            autoCompleteMenu: {
              filterOptions: undefined,
              noOptionsText: (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[600]}
                    textAlign="center"
                  >
                    No collections found
                  </Typography>
                </Box>
              ),
            },
          },
        };

        if (option.value === CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY) {
          return (
            <Box sx={{ mb: 1 }} key={option.value}>
              {/*  @ts-ignore -- onClick is throwing a type error but we should be able to ignore it here */}
              <ContentCalendarCollectionMenuItem
                selectIconPosition="start"
                {...commonMenuItemProps}
              />
            </Box>
          );
        }

        if (option.value === UNORGANIZED_POSTS_MENU_ITEM_VALUE_KEY) {
          return (
            <Box sx={{ mb: 1 }} key={option.value}>
              <UnorganizedPostsMenuItem
                selected={selectedCollections.some(
                  (c) => c.value === option.value,
                )}
                onClick={() => {
                  setSelectedCollections((prev) => {
                    if (prev.some((c) => c.value === option.value)) {
                      return prev.filter((c) => c.value !== option.value);
                    }

                    return [
                      ...prev,
                      {
                        filterType: JuiceboxFilterType.PostCollections,
                        value: option.value,
                        label: option.label,
                      },
                    ];
                  });
                }}
              />
            </Box>
          );
        }

        if (!collection) {
          return null;
        }

        return (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              width: '100%',
              cursor: 'pointer',
              borderRadius: 3,
              mb: 1,
            }}
            key={collection.id}
          >
            <CollectionMenuItemView
              collection={collection}
              selectIconPosition="start"
              {...commonMenuItemProps}
            />
          </Box>
        );
      }}
      placeholder="Search"
      popoverProps={{
        open: isOpen,
        onClose,
        sx: {
          '& .MuiPaper-root': {
            boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
          },
        },
        PaperProps: {
          sx: {
            width: 300,
            border: 'none',
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: 'blur(20px)',
            '& ul': {
              '::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 0,
              msOverflowStyle: 'none',
            },
          },
        },
      }}
      inputSx={{
        border: 'none',
        mb: 1,
        '& .MuiInputBase-root': {
          padding: `${theme.spacing(2, 3)} !important`,
          ...theme.typography['subhead-lg'],
          color: theme.colors?.utility[1100],
        },
      }}
    />
  );
};
