import { NetworkStatus } from '@apollo/client';
import { Badge, Box, Checkbox, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { MoreMenuItem } from 'components/common/Menu';
import { TextSwitch } from 'components/common/TextSwitch/TextSwitch';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { IconLinearGrid2 } from 'components/icons/components/linear/IconLinearGrid2';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import {
  SOCIAL_LISTENING_LIST_FILTERS,
  SOCIAL_LISTENING_LIST_SORT_BY,
  SOCIAL_LISTENING_POST_VIEW_SETTING,
  SOCIAL_LISTENING_TOPIC_IDS_FILTER,
  SocialMediaListeningPostsGridView,
  SocialMediaListeningPostsListView,
  useSLBrandPermissions,
  useSocialPostsList,
  UseSocialPostsListProps,
} from 'features/socialMediaListening';
import {
  SLPageBrandSelector,
  SocialMediaListeningWeeklySnapShotSummary,
} from 'features/socialMediaListening/components';
import { BrandParsedHours } from 'features/socialMediaListening/components/brandParsedHours';
import { SocialMediaListeningFilterSelection } from 'features/socialMediaListening/components/filterSelection/SocialMediaListeningFilterSelection';
import { FilterOption } from 'features/socialMediaListening/components/filterSelection/types';
import { SocialMediaListeningSelectedFiltersView } from 'features/socialMediaListening/views/selectedFilters';
import { TopicLabel } from 'features/topic';
import {
  BrandSocialPostEngagementStatus,
  ExtendedSocialPostTypeForFilter,
  Platform,
  PlotFeature,
  SocialListeningPostsSortBy,
  SortOrder,
  useGetBrandForSocialMediaListeningQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import {
  socialListeningFilterOptions,
  SocialListeningFiltersKey,
  socialListeningSortOptions,
} from './constant';

export const SocialMediaListeningPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);

  const [filterByOptions] = useState<FilterOption<any>[]>(
    socialListeningFilterOptions,
  );

  const { brandId = '' } = useParams();
  const { hasFullAccess } = useSLBrandPermissions({
    brandId,
  });

  const { value: view, setValue: setView } = useLocalStorage<'grid' | 'list'>(
    SOCIAL_LISTENING_POST_VIEW_SETTING + brandId,
    'grid',
  );

  const { value: filteredTopics, setValue: setFilteredTopics } =
    useLocalStorage<string[]>(SOCIAL_LISTENING_TOPIC_IDS_FILTER + brandId, []);

  const { value: selectedFilters, setValue: setSelectedFilters } =
    useLocalStorage<{
      [key in SocialListeningFiltersKey]:
        | string
        | (string | Date | null)[]
        | boolean
        | undefined
        | number
        | ExtendedSocialPostTypeForFilter[];
    }>(SOCIAL_LISTENING_LIST_FILTERS + brandId, {
      [SocialListeningFiltersKey.Platforms]: [
        Platform.Tiktok,
        Platform.Instagram,
      ],
      [SocialListeningFiltersKey.Statuses]: [
        BrandSocialPostEngagementStatus.NotStarted,
      ],
      [SocialListeningFiltersKey.ExpiredStories]: true,
      [SocialListeningFiltersKey.Followers]: [],
      [SocialListeningFiltersKey.Sentiments]: [],
      [SocialListeningFiltersKey.Period]: [],
      [SocialListeningFiltersKey.TopicRelevancy]: 0.5,
      [SocialListeningFiltersKey.OrganicPaid]: undefined,
      [SocialListeningFiltersKey.MinimumPlayCount]: 0,
      [SocialListeningFiltersKey.Types]: Object.values(
        ExtendedSocialPostTypeForFilter,
      ),
    });

  const { value: selectedSortByKey, setValue: setSelectedSortByKey } =
    useLocalStorage(
      SOCIAL_LISTENING_LIST_SORT_BY + brandId,
      socialListeningSortOptions[0].key,
    );
  const {
    data: brandData,
    loading: brandQueryLoading,
    networkStatus,
  } = useGetBrandForSocialMediaListeningQuery({
    variables: {
      brandId,
    },
    skip: !brandId,
  });
  const topics = brandData?.brand.topics ?? [];

  const [sortOrder, setSortOrder] = useState(SortOrder.Desc);

  useEffect(() => {
    if (!brandData?.brand && networkStatus === NetworkStatus.ready) {
      // Data for brands is not available
      navigate(PlotRoutes.socialListeningIntro(), {
        replace: true,
      });
    }
  }, [brandData, networkStatus]); // eslint-disable-line

  const currentBrand = brandData?.brand;

  const { loading, refetch, socialPostsData, fetchMorePostSuggestions } =
    useSocialPostsList({
      brandId: currentBrand?.id || '',
      sortBy: selectedSortByKey,
      sortOrder,
      filters: {
        ...selectedFilters,
        topicIds: filteredTopics,
      },
    });

  useEffect(() => {
    if (currentBrand) {
      refetch();
    }
  }, [selectedFilters, filteredTopics]); // eslint-disable-line

  const socialPosts = socialPostsData?.getPaginatedSocialPosts.data;
  const hasNextPage =
    socialPostsData?.getPaginatedSocialPosts.pageInfo.hasNextPage;

  const {
    setRenderTitle,
    setHeaderSx,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
  } = useCustomHeaderContext();

  useEffect(() => {
    const renderMenuExtraLeft = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );

    addRenderMenuExtraLeft(renderMenuExtraLeft, 1);

    setHeaderSx({
      borderBottom: `1px solid transparent`,
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
      removeRenderMenuExtraLeft(renderMenuExtraLeft);
    };
  }, [location.pathname]); // eslint-disable-line

  if (brandQueryLoading) {
    return <Box>Loading...</Box>;
  }

  if (!brandData?.brand) {
    return null;
  }

  return (
    <Box m={theme.spacing(16, 8, 8)} key={brandId}>
      {/* <SocialMediaListeningZeroStateView /> */}
      <Box py={4} gap={4} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SLPageBrandSelector
            generateUrl={(brandId) =>
              PlotRoutes.socialListeningForBrand(brandId)
            }
          />
          <Box
            marginLeft="auto"
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            <BrandParsedHours />
            {currentBrand && (
              <Box
                borderRadius={10}
                bgcolor={theme.colors?.utility[275]}
                padding={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'center',
                }}
                component="button"
                onClick={() => {
                  navigate(
                    PlotRoutes.socialListeningSettings({
                      brandId: currentBrand.id,
                    }),
                  );
                }}
              >
                <IconBoldSettings2
                  size={22}
                  color={theme.colors?.utility[600]}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
          Weekly snapshot
        </Typography>

        <SocialMediaListeningWeeklySnapShotSummary
          brandId={currentBrand?.id || ''}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
          Recent Posts
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <TextSwitch
            textOptionLeft={<IconLinearGrid2 size={14} />}
            textOptionRight={<IconOutlineBulletedList size={14} />}
            selectedOptionTextColor={theme.colors?.primary.white}
            sx={{
              bgcolor: theme.colors?.utility[275],
              height: theme.spacing(10),
              width: theme.spacing(20),
              p: theme.spacing(0.5),
              borderRadius: theme.spacing(25),
            }}
            isLeftOptionSelected={view === 'grid'}
            onClick={(isLeftOptionSelected) =>
              setView(isLeftOptionSelected ? 'grid' : 'list')
            }
            componentProps={{
              text: { sx: { color: theme.colors?.primary.black } },
              indicator: {
                sx: {
                  bgcolor: theme.colors?.primary.black,
                  borderRadius: theme.spacing(25),
                  height: '38px',
                },
              },
            }}
          />
          <ContextMenu
            sx={{
              '& .MuiMenu-list': {
                padding: `${theme.spacing(3)} !important`,
                gap: `${theme.spacing(1)} !important`,
              },
              '& li': {
                p: theme.spacing(1, 2),
              },
              '& li:hover': {
                backgroundColor: 'transparent',
              },
            }}
            renderButton={() => {
              const isSelected = filteredTopics.length > 0;
              return (
                <Badge
                  badgeContent={filteredTopics.length}
                  color="primary"
                  invisible={!isSelected}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: theme.colors?.primary.black,
                      minWidth: 16,
                      height: 16,
                      fontSize: 11,
                      padding: 0,
                    },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={{
                      cursor: 'pointer',
                      borderRadius: theme.spacing(3),
                      backgroundColor:
                        theme.colors?.utility[isSelected ? 400 : 275],
                      p: theme.spacing(3, 3),
                    }}
                  >
                    <IconLinearClipboardText
                      size={16}
                      color={theme.colors?.utility[isSelected ? 800 : 700]}
                    />
                    <Typography
                      variant="body-xl"
                      fontWeight={isSelected ? 600 : 500}
                      color={theme.colors?.utility[isSelected ? 800 : 700]}
                    >
                      Topics
                    </Typography>
                  </Box>
                </Badge>
              );
            }}
            options={[
              ...topics.map((topic) => ({
                renderOption: () => (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    width="100%"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFilteredTopics((prev) => {
                        if (prev.includes(topic.id)) {
                          return prev.filter((id) => id !== topic.id);
                        }
                        return [...prev, topic.id];
                      });
                    }}
                  >
                    <Checkbox
                      disableRipple
                      checked={filteredTopics.includes(topic.id)}
                      sx={{
                        p: 1,
                        color: theme.colors?.primary.black,
                        '&.MuiCheckbox-root': {
                          color: theme.colors?.primary.black,
                        },
                      }}
                    />
                    <Box flex={1}>
                      <Typography variant="body-lg" display="block">
                        {topic.name}
                      </Typography>
                      <TopicLabel topic={topic} />
                    </Box>
                  </Box>
                ),
              })),
              ...(hasFullAccess
                ? [
                    {
                      renderOption: () => (
                        <Box
                          sx={{
                            width: '100%',
                            my: theme.spacing(2),
                            borderTop: `1px solid ${theme.colors?.utility[500]}`,
                          }}
                        />
                      ),
                    },
                    {
                      renderOption: () => (
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <MoreMenuItem
                            disableRipple
                            sx={{
                              minWidth: 240,
                              width: '100%',
                            }}
                            label={
                              <Typography
                                display="inline-block"
                                variant="body-md"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                  borderRadius: theme.spacing(2),
                                  p: theme.spacing(3, 2),
                                  '&:hover': {
                                    backgroundColor: theme.colors?.utility[275],
                                  },
                                }}
                              >
                                Create a New Topic
                                <IconLinearArrowRight size={16} />
                              </Typography>
                            }
                          >
                            <Box
                              sx={{
                                width: 270,
                                py: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                              }}
                            >
                              <Link
                                to={PlotRoutes.socialListeningOnboarding(
                                  'topic',
                                  brandId,
                                )}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    padding: theme.spacing(3),
                                    borderRadius: theme.spacing(3),
                                    '&:hover': {
                                      backgroundColor:
                                        theme.colors?.utility[275],
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="body-lg"
                                    fontWeight={500}
                                  >
                                    Topic Insights
                                  </Typography>
                                  <Typography
                                    variant="body-sm"
                                    fontWeight={500}
                                    color={theme.colors?.utility[900]}
                                  >
                                    Track conversations across customized topics
                                  </Typography>
                                </Box>
                              </Link>
                              {creatorTrackingEnabled && (
                                <Link
                                  to={PlotRoutes.socialListeningOnboarding(
                                    'creator',
                                    brandId,
                                  )}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 2,
                                      padding: theme.spacing(3),
                                      borderRadius: theme.spacing(3),
                                      '&:hover': {
                                        backgroundColor:
                                          theme.colors?.utility[275],
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="body-lg"
                                      fontWeight={500}
                                    >
                                      Creator Focus
                                    </Typography>
                                    <Typography
                                      variant="body-sm"
                                      fontWeight={500}
                                      color={theme.colors?.utility[900]}
                                    >
                                      Track specific creators discussing your
                                      key topics
                                    </Typography>
                                  </Box>
                                </Link>
                              )}
                            </Box>
                          </MoreMenuItem>
                        </Box>
                      ),
                    },
                  ]
                : []),
            ]}
          />
          <SocialMediaListeningFilterSelection
            brandId={currentBrand?.id || ''}
            filterByOptions={filterByOptions}
            sortByOptions={socialListeningSortOptions}
            onFilterUpdated={(filter) => {
              setSelectedFilters({ ...filter });
            }}
            onSortUpdated={(key, order) => {
              setSelectedSortByKey(key as SocialListeningPostsSortBy);
              setSortOrder(order);
            }}
            selectedFilters={selectedFilters as any}
            selectedSortBy={selectedSortByKey}
            showExpiredStories={
              selectedFilters[
                SocialListeningFiltersKey.ExpiredStories
              ] as boolean
            }
            setShowExpiredStories={(show) => {
              setSelectedFilters({
                ...selectedFilters,
                showExpiredStories: show,
              });
            }}
          />
        </Box>
      </Box>

      <SocialMediaListeningSelectedFiltersView
        selectedFilters={selectedFilters}
        filterOptions={filterByOptions}
        onFilterUpdated={(filters) => {
          setSelectedFilters({ ...filters });
        }}
      />

      <InfiniteScroll
        hasMore={hasNextPage}
        initialLoad={false}
        loadMore={fetchMorePostSuggestions}
        threshold={1024}
        getScrollParent={() =>
          document.querySelector(
            '.collection-cjb-window-view-scrollable-container',
          )
        }
      >
        {socialPosts?.length && socialPosts?.length > 0 ? (
          <Box
            sx={{
              maxWidth: `calc(100vw - ${theme.spacing(16)})`,
              overflowX: 'auto',
            }}
          >
            {view === 'grid' ? (
              <SocialMediaListeningPostsGridView
                brandId={currentBrand?.id || ''}
                socialPosts={socialPosts}
                searchCriteria={window.btoa(
                  JSON.stringify({
                    brandId: currentBrand?.id ?? '',
                    filters: { ...selectedFilters, topicIds: filteredTopics },
                    sortBy: selectedSortByKey,
                    sortOrder,
                  } as UseSocialPostsListProps),
                )}
                sortBy={selectedSortByKey}
              />
            ) : (
              <SocialMediaListeningPostsListView
                brandId={currentBrand?.id || ''}
                socialPosts={socialPosts}
                searchCriteria={window.btoa(
                  JSON.stringify({
                    brandId: currentBrand?.id ?? '',
                    filters: { ...selectedFilters, topicIds: filteredTopics },
                    sortBy: selectedSortByKey,
                    sortOrder,
                  } as UseSocialPostsListProps),
                )}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography variant="body-lg" color={theme.colors?.utility[700]}>
              {loading ? 'Loading...' : 'No posts'}
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};
